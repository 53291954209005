import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import DateFnsUtils from '@date-io/date-fns';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  isMobile
} from "react-device-detect";


import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  WhatsappIcon as WhatsAppIcon2,
} from "react-share";

import { Link } from 'react-router-dom';

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ContactUsBoard extends Component {
  componentDidMount () {
    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     name: '',
     phoneNumber: '',
     text: '',
     sent: false
   }
  }

  validate = () => {
    let name = this.state.name;
    let phoneNumber = this.state.phoneNumber;
    let text = this.state.text;

    if (
      (name !== undefined && name !== null && name !== '') &&
      (phoneNumber !== undefined && phoneNumber !== null && phoneNumber !== '') &&
      (text !== undefined && text !== null && text !== '')
    ) {
      var regex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;

      if (regex.test(phoneNumber)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

    return false;
  }

  send = async () => {
    if (this.validate()) {
      axios.post(API + 'contact-us', {
        name: this.state.name,
        phoneNumber: this.state.phoneNumber,
        text: this.state.text
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({sent: true});
        } else {
          // alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    } else {
      alert('יש למלא את כל השדות ולוודא את תקינות מספר הטלפון!');
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'not_logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.contactus}</h2>
                <h4 style={{textAlign: 'right'}}>{strings.mymessagessivan}</h4>

                {this.state.sent ? (
                  <div>
                    <h3 style={{color: 'green', direction: 'rtl'}}>ההודעה נשלחה בהצלחה!</h3>
                    <h4 style={{color: 'green'}}>ניצור קשר בהקדם</h4>
                    <Link to='/board' style={{textDecoration: 'none', color: 'black'}}><Button>לחצו עליי לחזרה ללוח המודעות</Button></Link>
                  </div>
                ) : (
                  <div style={{textAlign: 'right',}}>
                    <p style={{direction: 'rtl'}}>{strings.fullname}:</p>
                    <TextField
                      placeholder={'שם מלא'}
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '30%'
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                    />
                    <br />
                    <p style={{direction: 'rtl'}}>{strings.phonenumber}:</p>
                    <TextField
                      placeholder={'מספר טלפון'}
                      value={this.state.phoneNumber}
                      onChange={(e) => this.setState({phoneNumber: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '30%'
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                    />
                    <br />
                    <p style={{direction: 'rtl'}}>{strings.contactusabout}:</p>
                    <textarea
                      placeholder={'כתוב לנו איך נוכל לעזור לך'}
                      value={this.state.text}
                      rows={5}
                      onChange={(e) => this.setState({text: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '30%'
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                    ></textarea>
                    <br />
                    <Button color={'primary'} variant={'contained'} onClick={(e) => this.send()}>שליחה</Button>
                  </div>
                )}

              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ContactUsBoard;
