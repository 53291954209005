import React, { Component } from 'react';
import '../App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import Cookies from 'universal-cookie';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {
  Link
} from "react-router-dom";
import {
  isMobile
} from "react-device-detect";
import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';

const cookies = new Cookies();

const hebrew = "עברית";
const arabic = "عربيه";
const english = "English";
const russian = "русский";

const messageLoggedOutSucces_heb = "התנתקת בהצלחה";
const messageLoggedOutSucces_ar = "התנתקת בהצלחה בערבית";

const mainColor = '#9d58cb';
const UID = '5fc0e4385f2d973c46a8e220';

class Header extends Component {
  constructor(props) {
   super(props);
   this.state = {
     language: 'hebrew',
     logo: ''
   }
  }

  componentDidMount () {
    this.getSetLanguage();
    this.getStore();
  }

  getStore = () => {
    axios.get(API + 'store?id='+UID).then(store => {
      this.setState({
        logo: store.data.data.logo,
        slogan: store.data.data.slogan
      });
    }).catch(err => {
      console.log(err);
    });
  }

  getSetLanguage = () => {
    let l = cookies.get('language');

    if (l === "" || l === undefined || l === null) {
      cookies.set('language', 'hebrew', { path: '/' })
      this.setState({language: 'hebrew'});
    } else {
      if (l === "hebrew") {
        this.setState({language: 'hebrew'});
      } else if (l === "arabic") {
        this.setState({language: 'arabic'});
      } else if (l === "russian") {
        this.setState({language: 'russian'});
      } else if (l === "english") {
        this.setState({language: 'english'});
      } else {
        this.setState({language: 'hebrew'});
      }
    }
  }

  setLanguage = () => {
    if (this.state.language === "arabic") {
      cookies.set('language', 'arabic', { path: '/' })
      this.setState({language: 'arabic'});
      window.location.reload();
    } else if (this.state.language === "hebrew") {
      cookies.set('language', 'hebrew', { path: '/' })
      this.setState({language: 'hebrew'});
      window.location.reload();
    } else if (this.state.language === "english") {
      cookies.set('language', 'english', { path: '/' })
      this.setState({language: 'english'});
      window.location.reload();
    } else if (this.state.language === "russian") {
      cookies.set('language', 'russian', { path: '/' })
      this.setState({language: 'russian'});
      window.location.reload();
    }
  }

  logout = () => {
    if (window.confirm("האם את/ה בטוח/ה שברצונך להתנתק?")) {
      cookies.remove('token___school');
      window.location.href='/';

      // if (this.state.language === "hebrew") alert(messageLoggedOutSucces_heb);
      // else alert(messageLoggedOutSucces_ar);
    }
  }

  fixDate = (date) => {
    date = date.toString();

    if (date.length === 1) return '0' + date;

    return date;
  }

  lastSeenForHumans = (lastSeen) => {
    let ls = lastSeen;

    if (ls === undefined || ls === null || ls === '') return ls;

    let date = new Date(ls);

    let day = date.getDate();
    day = this.fixDate(day);

    let month = date.getMonth();
    month = this.fixDate(month);

    let year = date.getFullYear();

    let time = date.getHours() + ':' + date.getMinutes();

    const prefix = 'כנסתך האחרונה התבצעה ביום';
    const time_prefix = 'בשעה';

    let stringified_date = day + '/' + month + '/' + year + ' ' + time_prefix + ' ' + time;

    return prefix + ' ' + stringified_date;
  }

  changeLanguage = (language) => {
     this.setState({language: language}, () => {
       this.setLanguage();
     })
  }
  render() {
    return (
      <AppBar position="static" style={{backgroundColor: mainColor === '' ? '#A8A8A8' : mainColor}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          {/*(this.props.logo === '' || this.props.logo === undefined || this.props.logo === null) ? (
            <img src={'https://admin.sivan-school.com/images/logo.png'} style={{width: 80}}/>
          ) : (
            <img src={'https://sivan-school.com/images/'+this.props.logo} style={{width: 80}}/>
          )*/}
          <img src={'https://sivan-school.com/images/'+this.state.logo} style={{width: 100}}/>
          {this.props.type === "logged" ? (
            <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {!isMobile && (
                  <p>{this.lastSeenForHumans(this.props.lastSeen)}</p>
                )}

                &nbsp;
                &nbsp;
                &nbsp;
                {/*<Button onClick={(e) => this.setLanguage()}>
                  {this.state.language === "hebrew" ? arabic : hebrew}
                </Button>*/}
                <Select
                  value={this.state.language}
                  onChange={(e) => this.changeLanguage(e.target.value)}
                >
                  <MenuItem value={'hebrew'}>{hebrew}</MenuItem>
                  <MenuItem value={'arabic'}>{arabic}</MenuItem>
                  <MenuItem value={'english'}>{english}</MenuItem>
                  <MenuItem value={'russian'}>{russian}</MenuItem>
                </Select>


                <IconButton variant="ghost" onClick={(e) => this.logout()}>
                 <ExitToAppIcon style={{color: '#404448'}} />
                </IconButton>


                <Link to='/board' style={{textDecoration: 'none'}}><IconButton variant="ghost">
                 <AddShoppingCartIcon style={{color: '#404448'}} />
                </IconButton></Link>

                {/* <Link to='/messages' style={{textDecoration: 'none'}}><IconButton variant="ghost">
                 <MailOutlineIcon style={{color: '#404448'}} />
                </IconButton></Link>

                <Link to='/settings' style={{textDecoration: 'none'}}><IconButton variant="ghost">
                 <SettingsIcon style={{color: '#404448'}} />
                </IconButton></Link> */}


                <Link to='/' style={{textDecoration: 'none'}}><IconButton variant="ghost">
                 <HomeIcon style={{color: '#404448'}} />
                </IconButton></Link>
              </div>
            </React.Fragment>
          ) : (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {/*<Button onClick={(e) => this.setLanguage()}>
              {this.state.language === "hebrew" ? arabic : hebrew}
            </Button>*/}

            <Link to='/login' style={{textDecoration: 'underline', fontSize: '1em', color: 'white'}}>
              <p>התחבר/י</p>
            </Link>
            &nbsp;
            &nbsp;
            &nbsp;
            <Select
              value={this.state.language}
              onChange={(e) => this.changeLanguage(e.target.value)}
            >
              <MenuItem value={'hebrew'}>{hebrew}</MenuItem>
              <MenuItem value={'arabic'}>{arabic}</MenuItem>
              <MenuItem value={'english'}>{english}</MenuItem>
              <MenuItem value={'russian'}>{russian}</MenuItem>
            </Select>
              <Link to='/board' target="_blank" style={{textDecoration: 'none'}}><IconButton variant="ghost">
               <AddShoppingCartIcon style={{color: '#404448'}} />
              </IconButton></Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}

export default Header;
