import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import DateFnsUtils from '@date-io/date-fns';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  isMobile
} from "react-device-detect";

import { Link } from 'react-router-dom';

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  WhatsappIcon as WhatsAppIcon2,
} from "react-share";

const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SivanBoardPageGuest extends Component {
  componentDidMount () {
    this.getMessage();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     message: [],
     lastSeen: '',
     language: 'he',
     me: ''
   }
  }

  getMessage = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({messageId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'guest/sivanboardmessage-display?id='+this.state.messageId)
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            message: response.data.data.sivanboardmessage
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
        alert(strings.errorPleaseTryAgain);
      });
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'login'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>{strings.mymessagessivan}</h2>
              </div>
            </center>
          </Grid>

          {this.state.message.sold ? (
            <Grid item xs={12}>
              <Card style={{textAlign: 'right', direction: 'rtl', padding: '2%'}}>
                {isMobile ? (
                  <div style={{marginRight: '5%', maxWidth: '100%'}}>
                    <center>
                    <Chip color="secondary" label="נמכר" style={{fontSize: '1.4em'}}/>
                    {(this.state.message.category !== null && this.state.message.category !== undefined && this.state.message.category !== '') && (<Chip color="primary" variant={'outlined'} label={this.state.message.category} style={{fontSize: '1.4em'}}/>)}
                    <br /><br />
                    <img
                      src={'https://sivan-school.com/images/'+this.state.message.picture}
                      style={{width: 250, height: 250, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                    />
                    </center>
                    <div>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.state.message.freetext}</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {this.state.message.price} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {this.state.message.priceWanted === undefined ? 0 : this.state.message.priceWanted} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>הנחה באחוזים: {parseFloat((((this.state.message.price) - (this.state.message.priceWanted)) / (this.state.message.price)) * 100).toFixed(2)}%</p>

                      <FacebookShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookMessengerIcon size={36} round={true} />
                      </FacebookMessengerShareButton>
                      <WhatsappShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <WhatsAppIcon2 size={36} round={true} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <TelegramIcon size={36} round={true} />
                      </TelegramShareButton>
                      <EmailShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <EmailIcon size={36} round={true} />
                      </EmailShareButton>

                      {this.state.me !== this.state.message.userId && (
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+this.state.message.phoneNumber, '_blank')}>
                        <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                        &nbsp;&nbsp;
                        <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>
                      )}

                    </div>
                  </div>
                ) : (
                  <div style={{marginRight: '5%', maxWidth: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>
                      <Chip color="secondary" label="נמכר" style={{fontSize: '1.4em'}}/>
                      {(this.state.message.category !== null && this.state.message.category !== undefined && this.state.message.category !== '') && (<Chip color="primary" variant={'outlined'} label={this.state.message.category} style={{fontSize: '1.4em'}}/>)}
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.state.message.freetext}</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {this.state.message.price} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {this.state.message.priceWanted === undefined ? 0 : this.state.message.priceWanted} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>הנחה באחוזים: {parseFloat((((this.state.message.price) - (this.state.message.priceWanted)) / (this.state.message.price)) * 100).toFixed(2)}%</p>

                      <FacebookShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookMessengerIcon size={36} round={true} />
                      </FacebookMessengerShareButton>
                      <WhatsappShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <WhatsAppIcon2 size={36} round={true} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <TelegramIcon size={36} round={true} />
                      </TelegramShareButton>
                      <EmailShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <EmailIcon size={36} round={true} />
                      </EmailShareButton>

                      {this.state.me !== this.state.message.userId && (
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+this.state.message.phoneNumber, '_blank')}>
                        <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                        &nbsp;&nbsp;
                        <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>
                      )}

                    </div>
                    <img
                    src={'https://sivan-school.com/images/'+this.state.message.picture}
                      style={{width: 150, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                    />
                  </div>
                )}
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card style={{textAlign: 'right', direction: 'rtl', padding: '2%'}}>
                {isMobile ? (
                  <div style={{marginRight: '5%', maxWidth: '100%'}}>
                    <center>
                    <img
                      src={'https://sivan-school.com/images/'+this.state.message.picture}
                      style={{width: 250, height: 250, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                    />
                    </center>
                    <div>
                      {(this.state.message.category !== null && this.state.message.category !== undefined && this.state.message.category !== '') && (<Chip color="primary" variant={'outlined'} label={this.state.message.category} style={{fontSize: '1.4em'}}/>)}
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.state.message.freetext}</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {this.state.message.price} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {this.state.message.priceWanted === undefined ? 0 : this.state.message.priceWanted} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>הנחה באחוזים: {parseFloat((((this.state.message.price) - (this.state.message.priceWanted)) / (this.state.message.price)) * 100).toFixed(2)}%</p>

                      <FacebookShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookMessengerIcon size={36} round={true} />
                      </FacebookMessengerShareButton>
                      <WhatsappShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <WhatsAppIcon2 size={36} round={true} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <TelegramIcon size={36} round={true} />
                      </TelegramShareButton>
                      <EmailShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <EmailIcon size={36} round={true} />
                      </EmailShareButton>

                      {this.state.message.isLastMinute && (
                        <p style={{fontSize: '1.2em', fontWeight: 'bold', color: 'orange'}}>מוצר זה בעל תוקף מימוש של עד {new Date(this.state.message.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(this.state.message.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(this.state.message.lastMinuteMaxDate).getFullYear()}</p>
                      )}

                      {this.state.me !== this.state.message.userId && (
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+this.state.message.phoneNumber, '_blank')}>
                        <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                        &nbsp;&nbsp;
                        <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>
                      )}

                      {this.state.me === this.state.message.userId && (
                        <React.Fragment>
                          <br />
                          <br /><Button variant={'contained'} style={{backgroundColor: 'red'}} onClick={(e) => this.setSold(this.state.message._id)}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>סמן מודעה זו כנמכרה</span>

                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Link to={'/board/edit?id='+this.state.message._id} style={{textDecoration: 'none'}}><Button variant={'contained'} color={'primary'}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>ערוך מודעה</span>
                          </Button></Link>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{marginRight: '5%', maxWidth: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>
                      {(this.state.message.category !== null && this.state.message.category !== undefined && this.state.message.category !== '') && (<Chip color="primary" variant={'outlined'} label={this.state.message.category} style={{fontSize: '1.4em'}}/>)}
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.state.message.freetext}</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר עלות: {this.state.message.price} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>מחיר מבוקש: {this.state.message.priceWanted === undefined ? 0 : this.state.message.priceWanted} ש"ח</p>
                      <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>הנחה באחוזים: {parseFloat((((this.state.message.price) - (this.state.message.priceWanted)) / (this.state.message.price)) * 100).toFixed(2)}%</p>

                      <FacebookShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <FacebookMessengerIcon size={36} round={true} />
                      </FacebookMessengerShareButton>
                      <WhatsappShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <WhatsAppIcon2 size={36} round={true} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <TelegramIcon size={36} round={true} />
                      </TelegramShareButton>
                      <EmailShareButton
                        url={'https://sivan-school.com/board/page?id='+this.state.message._id}
                        quote={this.state.message.freetext + ' | ' + this.state.message.priceWanted + ' ש"ח'}
                        hashtag="#sivanschool"
                      >
                         <EmailIcon size={36} round={true} />
                      </EmailShareButton>
<br /><br />
                      {this.state.message.isLastMinute && (
                        <p style={{fontSize: '1.2em', fontWeight: 'bold', color: 'orange'}}>מוצר זה בעל תוקף מימוש של עד {new Date(this.state.message.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(this.state.message.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(this.state.message.lastMinuteMaxDate).getFullYear()}</p>
                      )}

                      {this.state.me !== this.state.message.userId && (
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+this.state.message.phoneNumber, '_blank')}>
                        <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                        &nbsp;&nbsp;
                        <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>
                      )}

                      {this.state.me === this.state.message.userId && (
                        <React.Fragment>
                          <br />
                          <br /><Button variant={'contained'} style={{backgroundColor: 'red'}} onClick={(e) => this.setSold(this.state.message._id)}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>סמן מודעה זו כנמכרה</span>

                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Link to={'/board/edit?id='+this.state.message._id} style={{textDecoration: 'none'}}>
                          <Button variant={'contained'} color={'primary'}>
                            <span style={{fontSize: '1.2em', color: 'white'}}>ערוך מודעה</span>
                          </Button></Link>
                        </React.Fragment>
                      )}
                    </div>
                    <img
                      src={'https://sivan-school.com/images/'+this.state.message.picture}
                      style={{width: 150, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                    />
                  </div>
                )}
              </Card>
            </Grid>
          )}
          </Grid>
      </div>
    )
  }
}

export default SivanBoardPageGuest;
