import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { i18n } from '../components/i18n';
import LocalizedStrings from 'react-localization';
import CircularProgress from '@material-ui/core/CircularProgress';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import imageCompression from 'browser-image-compression';


const strings = new LocalizedStrings(i18n);

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();
const UID = '5fc0e4385f2d973c46a8e220';

class NewSivanBoardMessage extends Component {
  componentDidMount () {
    this.getMessages();
    this.getCategories();
    this.getStore();

    if (cookies.get('language') === "hebrew") {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    } else if (cookies.get('language') === "arabic") {
      this.setState({language: 'ar'});
      strings.setLanguage('ar');
    } else if (cookies.get('language') === "english") {
      this.setState({language: 'en'});
      strings.setLanguage('en');
    } else if (cookies.get('language') === "russian") {
      this.setState({language: 'ru'});
      strings.setLanguage('ru');
    } else {
      this.setState({language: 'he'});
      strings.setLanguage('he');
    }
  }

  constructor(props) {
   super(props);
   this.state = {
     messages: [],
     lastSeen: '',
     language: 'he',

     isLastMinute: false,
     lastMinuteMaxDate: new Date(Date.now()),
     price: 0,
     priceWanted: 0,
     phoneNumber: '',
     freetext: '',
     file: null,
     categories: [],
     categoriesById: {},
     children_categories:{},
     child_category: '',
     category: '',

     loading: false,

     mustExpdate: false,
     mustImage: false,
     showCostPrice: false,
     showPriceWanted: false,
     showDiscount: false,
     isBuy: false,

     logo: '',
     slogan: ''
   }
  }

  getStore = () => {
    axios.get(API + 'store?id='+UID).then(store => {
      this.setState({
        logo: store.data.data.logo,
        slogan: store.data.data.slogan
      });
    }).catch(err => {
      console.log(err);
      alert(strings.errorPleaseTryAgain);
    });
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0]}, () => {
        // console.log(this.fileSizeLimit());
        // if (!this.fileSizeLimit()) {
        //   alert('אנא בחרו תמונה מתחת ל2 מגה בייט');
        // }
      });
  }

  getMessages = async () => {
    axios.get(API + 'stores/sivanboardmessages?id='+UID, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.sivanboardmessages
        });
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  getCategories = async () => {
    axios.get(API + 'stores/s-categories?id='+UID, {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          categories: response.data.data.categories
        }, () => {
          let parent = [];
          let children = {};
          let categoriesById = {};

          this.state.categories.map((category) => {
            categoriesById[category._id] = category;

            if (category.parentCategory === '' || category.parentCategory === null || category.parentCategory === undefined) parent.push(category);
            else {
              if (children[category.parentCategory] === undefined) children[category.parentCategory] = [];
              children[category.parentCategory].push(category);
            }
          });

          this.setState({categoriesById: categoriesById, categories: parent, children_categories: children});

        });
      } else {
        // alert(strings.errorPleaseTryAgain);
      }
    })
    .catch(error => {
      console.log(error);
      alert(strings.errorPleaseTryAgain);
    });
  }

  handleIsLastMinute = () => {
    let value = this.state.isLastMinute;
    if (value) {
      this.setState({isLastMinute: false});
    } else {
      this.setState({isLastMinute: true});
    }
  }

  onFormSubmit = async (e) => {
    this.setState({loading: true});

    if (this.state.mustImage && this.state.file === null) {
      alert('חובה לבחור תמונה');
    } else {
      // if (this.state.file === null) {
      //   alert('חובה לבחור תמונה למוצר');
      //   this.setState({loading: false});
      // } else {
      e.preventDefault();
      const formData = new FormData();

      if (this.state.file !== null) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        const compressedFile = await imageCompression(this.state.file, options);
        const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})
        console.log(convertedBlobFile);
        formData.append('file', convertedBlobFile);
      }
      formData.append('isLastMinute', this.state.mustExpdate ? true : this.state.isLastMinute);
      formData.append('lastMinuteMaxDate',this.state.lastMinuteMaxDate);
      formData.append('price',this.state.price);
      formData.append('priceWanted',this.state.priceWanted);
      formData.append('phoneNumber',this.state.phoneNumber);
      formData.append('freetext',this.state.freetext);
      formData.append('category',this.state.category);
      formData.append('child_category',this.state.child_category);
      formData.append('storeId',UID);

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${cookies.get('token___school')}`
          }
      };

      axios.post(API + 'stores/sivanboardmessage',formData,config)
      .then((response) => {
        alert('המודעה שלך נשלחה ומחכה לאישור המערכת. תודה!');
        window.location.href='/board';
        this.setState({loading: false});
      }).catch((error) => {
        console.log(error);
        this.setState({loading: false});
      });
      // }
    }
  }

  fileSizeLimit = () => {
    if (this.state.file !== null) {
      let size = this.state.file.size;

      let sizeMB = (size / 1000000);

      const limit = 2;

      if (sizeMB > limit) return false;
      else return true;
    } else {
      return true;
    }
  }

  setCategory = (id, child=false) => {
    let categories = this.state.categories;
    categories.forEach((item, i) => {
      if (item._id === id) {
        console.log(item);
        if (child) {
          this.setState({
            child_category: item._id,
            mustExpdate: item.mustExpdate,
            mustImage: item.mustImage,
            showCostPrice: item.showCostPrice,
            showPriceWanted: item.showPriceWanted,
            showDiscount: item.showDiscount,
            isBuy: item.isBuy,
          })
        } else {
          this.setState({
            category: item._id,
            mustExpdate: item.mustExpdate,
            mustImage: item.mustImage,
            showCostPrice: item.showCostPrice,
            showPriceWanted: item.showPriceWanted,
            showDiscount: item.showDiscount,
            isBuy: item.isBuy,
          })
        }
        return item;
      }
    });

  }

  render() {
    return (
      <div>
      {this.state.loading ? (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header type={'logged'} logo={this.state.logo} lastSeen={this.state.lastSeen}/>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{maxWidth: '90%'}}>
                  <h2 style={{textAlign: 'right'}}>{strings.newmessageboardsivan}</h2>
                </div>
              </center>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{maxWidth: '90%', textAlign: 'center'}}>
                  <CircularProgress />
                  <h3 style={{textAlign: 'center', direction: 'rtl'}}>טוען, אנא המתן...</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <div className="App">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header type={'logged'} lastSeen={this.state.lastSeen}/>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{maxWidth: '90%'}}>
                  <h2 style={{textAlign: 'right'}}>{strings.newmessageboardsivan}</h2>
                </div>
              </center>
            </Grid>

            <Grid item xs={12}>
                <form onSubmit={this.onFormSubmit}>
                  <br />
                  {!this.state.mustExpdate && (
                    <div style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>
                      <Checkbox
                        checked={this.state.isLastMinute}
                        onChange={(e) => this.handleIsLastMinute()}
                      /> {strings.isproductexp}
                    </div>
                  )}


                  {(this.state.isLastMinute || this.state.mustExpdate) && (
                    <React.Fragment>
                      <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.productexp}:</p>
                      <TextField
                        value={this.state.lastMinuteMaxDate}
                        onChange={(e) => this.setState({lastMinuteMaxDate: e.target.value})}
                        type='date'
                        style={{
                          textAlign: 'right',
                          direction: 'rtl',
                          width: '90%'
                        }}
                      />
                    </React.Fragment>
                  )}

                  <center>
                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.category}:</p>
                  <Select
                    value={this.state.category}
                    onChange={(e) => this.setCategory(e.target.value)}
                    style={{
                      width:'90%',
                      textAlign:'right',
                      direction:'rtl'
                    }}
                  >
                    {this.state.categories.map((index) => {
                      return (
                        <MenuItem style={{
                          textAlign:'right',
                          direction:'rtl'
                        }} value={index._id}>{index.name}</MenuItem>
                      )
                    })}
                  </Select>

                  {this.state.children_categories[this.state.category] !== undefined && (
                    <React.Fragment>
                      {this.state.children_categories[this.state.category].length >= 1 && (
                        <React.Fragment>
                        <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.subcategory}:</p>
                        <Select
                          value={this.state.child_category}
                          onChange={(e) => this.setCategory(e.target.value, true)}
                          style={{
                            width:'90%',
                            textAlign:'right',
                            direction:'rtl'
                          }}
                        >
                          {this.state.children_categories[this.state.category].map((index) => {
                            return (
                              <MenuItem style={{
                                textAlign:'right',
                                direction:'rtl'
                              }} value={index._id}>{index.name}</MenuItem>
                            )
                          })}
                        </Select>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  </center>

                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.buycost}:</p>
                  <TextField
                    value={this.state.price}
                    onChange={(e) => this.setState({price: e.target.value})}
                    type='number'
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '90%'
                    }}
                  />

                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.wantedprice}:</p>
                  <TextField
                    value={this.state.priceWanted}
                    onChange={(e) => this.setState({priceWanted: e.target.value})}
                    type='number'
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '90%'
                    }}
                  />

                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.phonenumber}:</p>
                  <TextField
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({phoneNumber: e.target.value})}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '90%'
                    }}
                  />

                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>{strings.freetext}:</p>
                  <textarea
                    value={this.state.freetext}
                    onChange={(e) => this.setState({freetext: e.target.value})}
                    style={{
                      textAlign: 'right',
                      direction: 'rtl',
                      width: '90%'
                    }}
                    rows={5}
                  ></textarea>
                  <br />
                  <div style={{textAlign: 'right'}}>
                  <br />
                  <div class="upload-btn-wrapper" style={{marginRight: '5%'}}>
                    <label for="file-upload" class="custom-file-upload">
                        <i class="fa fa-cloud-upload"></i> {strings.chooseproductimages} {this.state.mustExpdate && (<span style={{color: 'red'}}>*</span>)}
                    </label>
                    <input type="file" id="file-upload" name="myImage" onChange={this.onChange} accept="image/*"/>
                  </div>



                  {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                    <React.Fragment>
                    {console.log(this.state.file.size)}
                    <p style={{color: 'black', fontSize: '1em', direction: 'rtl', marginRight: '5%'}}>{strings.filechosen}: {this.state.file.name}</p>
                    </React.Fragment>
                  )}

                  <br />

                  <center><Button type="submit" variant={'contained'} color={'primary'}>{strings.uploadtoboard}</Button></center>
                  </div>
                </form>
            </Grid>
          </Grid>
        </div>
      )}
      </div>
    )
  }
}

export default NewSivanBoardMessage;
