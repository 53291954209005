export const i18n = {
  he: {
    myschool: 'בית הספר שלי',
    myschools: 'בתי הספר שלי',
    school: 'בית ספר',
    main: 'ראשי',
    studentsmanagement: 'ניהול התלמידים',
    myemployees: 'המורים, המנהלים המקצועיים והפקידים שלי',
    employeename: 'שם העובד',
    position: 'תפקיד',
    createdat: 'נוצר בתאריך',
    edit: 'עריכה',
    status: 'סטטוס',
    frozen: 'מושהה',
    active: 'פעיל',
    emptyemployeesstring1: 'אין לך מורים או פקידים, אנא לחץ על',
    emptyemployeesstring2: 'להוספת מורה, מנהל מקצועי או פקיד',
    addnewemployee: 'הוספת עובד חדש',
    firstname: 'שם פרטי',
    lastname: 'שם משפחה',
    phonenumber: 'מספר טלפון',
    idnumber: 'ת.ז',
    email: 'אימייל',
    type: 'סוג',
    teacher: 'מורה',
    professional: 'מנהל מקצועי',
    professionalteacher: 'מורה/מנהל מקצועי',
    clerk: 'פקיד/ה',
    professionalnumber: 'מספר הנהלה מקצועית',
    ispartner: 'האם שותף',
    yes: 'כן',
    no: 'לא',
    signuppaymentnumberofigrot: 'דמי רישום מספר אגרות חודשי לבית הספר',
    insidetestpaymentnumberofigrot: 'מבחן פנימי מספר אגרות חודשי לבית הספר',
    signuppaymentcoststudent: 'סכום למבחן פנימי - עלות לתלמיד',
    insidetestpaymentcoststudent: 'סכום למבחן פנימי - תקבול המורה',
    signuppaymentcostteacher: 'סכום לדמי רישום - עלות לתלמיד',
    insidetestpaymentcostteacher: 'סכום לדמי רישום - תקבול המורה',
    password: 'סיסמה',
    create: 'יצירה',
    update: 'עדכון',
    editexistingemployee: 'עריכת משתמש קיים',
    freezeemployee: '?הקפאת עובד',
    addnewstudent: 'הוספת תלמיד חדש',
    birthday: 'תאריך לידה',
    gender: 'מגדר',
    male: 'זכר',
    female: 'נקבה',
    othergender: 'מעדיפים לא לציין',
    licensetype: 'סוג רשיון',
    allowedlicensetypelearn: 'סוגי רשיון מותרים ללימוד',
    studentstatus: 'סטטוס התלמיד',
    active: 'פעיל',
    inactive: 'לא פעיל',
    passedtest: 'עבר טסט',
    cansignwithbrosh: 'רשאי/ת להרשם בברוש',
    broshsignupdate: 'תאריך רישום בברוש',
    broshclerk: 'הפקיד הרושם',
    paidsignupfee: 'שילם דמי רישום',
    ptor: 'פטור',
    paidinsidetestfee: 'שילם מבחן פנימי',
    editschool: 'עריכת בית הספר',
    schoolname: 'שם בית הספר',
    schooladdress: 'כתובת בית הספר',
    postalcode: 'מיקוד',
    schoolemail: 'אי-מייל בית הספר',
    cpaemail: 'אי-מייל רואה חשבון',
    cpe: 'רואה חשבון',
    officialidnumber: 'ע.מ/ע.פ/ח.פ',
    schooladdress: 'כתובת למשלוח דואר',
    receipttaxinvoice: 'חשבונית מס קבלה',
    invoicenumber: 'חשבונית',
    receipt: 'קבלה',
    editstudent: 'עריכת תלמיד/ה',
    deletestudent: 'מחיקת התלמיד/ה',
    studentstatus: 'סטטוס התלמיד',
    officename: 'שם המשרד (רו"ח)',
    fax: 'מספר פקס',
    cpeaddress: 'כתובת משרד הרו"ח',
    cpepostalcode: 'מיקוד משרד הרו"ח',

    studentadd: 'הוספת תלמיד/ה',
    getexternalstudentlink: 'קבלת קישור להוספת תלמיד חיצונית',
    mystudents: 'התלמידים שלי',
    emptystudentstring1: 'אין לך תלמידים, אנא לחץ על',
    emptystudentstring2: 'להוספת תלמיד חדש',
    studentname: 'שם התלמיד',
    getexternalstudentlinktitle: 'תלמידים לאישור - מלינק חיצוני',
    nostudentstoapprove1: 'אין תלמידים לאישור. כשיהיו - הם יופיעו כאן',
    nostudentstoapprove2: 'להוספת תלמיד חדש',
    delete: 'מחיקה',
    approve: 'אישור',
    selfsignupthankyou: '!תלמיד/ה יקר/ה, תודה רבה על שיתוף הפעולה. ניצור איתך קשר בהקדם',
    selfsigndearstudent: 'תלמיד/ה יקר/ה, אנא מלאו את הפרטים ולחצו על כפתור האישור',
    selfsignattheend: 'בסיום, הודעה תשלח לבית הספר. מודה לכם על שיתוף הפעולה,',

    login: 'התחברות',
    username: 'שם משתמש',

    teachername: 'שם המורה',

    licensetypeA1: 'A1 - אופנוע עד 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeA2: 'A2 - אופנוע עד 6.14 כוחות סוס ועד 11 קילוואט',
    licensetypeA: 'A - אופנוע שהספק מנועו עולה על 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeB: 'B ידני - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeB_: 'B אוטומט - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeC: 'C - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeC1: 'C1 - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ק"ג ועד 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeCA: 'C+E - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ,גם אם צמוד אליו נגרר או נתמך',
    licensetypeD: 'D - אוטובוס',
    licensetypeD1: 'D1 - מונית, רכב סיור ואוטובוס זעיר ציבורי במשקל כולל מותר עד 5,000 ק"ג ומספר מקומות ישיבה עד 16 נוסעים מלבד הנהג',

    migrate: 'מעבר תלמיד/ה',
    migrateAction: 'העבר תלמיד/ה',
    chooseteacher: 'בחר מורה / מנהל מקצועי',

    errorPleaseTryAgain: 'שגיאה בחיבור לשרת, אנא נסו בשנית. אם הבעיה חוזרת על עצמה - יש לפנות לתמיכה שלנו. סליחה על האי נוחות הזמנית.',
    messageCopiedSuccess: 'הקישור הועתק בהצלחה',
    messageStudentUpdateSuccess: 'התלמיד עודכן בהצלחה',
    messageSchoolCreatedSuccess: 'בית ספר חדש נוצר בהצלחה',
    messageLoggedOutSucces: 'התנתקת בהצלחה',
    mustFillAllFields: 'עלייך למלא את כל שדות החובה',
    messageManagerUpdatedSuccess: 'המנהל/ת עודכנ/ה בהצלחה',
    phoneNotGood: 'יש למלא מספר טלפון תקני',
    idNumberNotGood: 'מספר תעודת זהות אינו תקני',
    messageUserCreatedSuccess: 'המשתמש נוצר בהצלחה',
    messageStudentAlreadyExists: 'התלמיד כבר קיים ושייך למורה',
    messageDearStudentAlreadyExists: 'תלמיד/ה יקר/ה, נראה שחשבונכם כבר קיים אצל המורה:',
    messsageThankYouContactShortly: 'תודה רבה! ניצור עימך קשר בהקדם',
    messageUserUpdatedSuccess: 'המשתמש עודכן בהצלחה',
    messageDeletionCancelled: 'המחיקה בוטלה',
    messageSchoolUpdatedSuccess: 'בית הספר עודכן בהצלחה',
    messageStudentDeletedSuccess: 'התלמיד/ה נמחק/ה בהצלחה',
    canAddClerk: 'יכול/ה להוסיף פקיד/ה',
    canBeChargedTwiceForSignupFee: 'האם ניתן לחייב תלמיד זה יותר מפעם אחת על דמי רישום',
    chooseteacherAndShareLink: 'בחר/י מורה ושתפ/י את הלינק הרשמה שלו לתלמידים',

    addnewstudentpayment: 'הוספת רשומת תשלום חדשה',
    choosestudent: 'בחר/י תלמיד מהרשימה',
    opencalendarhere: 'לחצו עליי לפתיחת הלוח שנה',
    choosedateofpayment: 'בחרו את תאריך התשלום',
    paywith: 'תשלום באמצעות',

    cash: "מזומן",
    cheque: "שיק",
    invoiceDelivered: 'חשבונית הופקה',
    bankTransfer: 'העברה בנקאית',
    creditcard: 'כרטיס אשראי',
    application: 'אפליקצייה',
    tranzila: 'סליקה',
    barter: 'ברטר',
    ptor: 'פטור',
    amountWithVAT: 'סכום לתשלום (כולל מע"מ)',
    payfor: 'תשלום עבור',
    signupFee: 'דמי רישום',
    signup: 'רישום',
    insideTestFee: 'דמי מבחן פנימי',
    insideTest: 'מבחן פנימי',
    choosebank: 'בחר בנק',
    branchNumber: 'מספר סניף',
    bankAccountNumber: 'מספר חשבון בנק',

    bankLeumi: 'בנק לאומי - 10',
    bankIsrael: 'בנק ישראל - 99',
    bankHapoalim: 'בנק הפועלים - 12',
    discontBank: 'בנק דיסקונט - 11',
    tfahotBank: 'בנק מזרחי טפחות - 20',
    internationalBank: 'בנק הבינלאומי הראשון - 31',
    otzarHayalBank: 'בנק אוצר החייל - 14',
    markantilDiscontBank: 'בנק מרכנתיל דיסקונט - 17',
    doarBank: 'בנק הדואר - 09',
    igudBank: 'בנק איגוד - 13',
    masadBank: 'בנק מסד - 46',
    poaliAgudatBank: 'בנק פועלי אגודת ישראל - 52',
    mashkantaotDiscontBank: 'בנק דיסקונט למשכנתאות - 90',
    mashkantaotLeumiBank: 'בנק לאומי למשכנתאות בע"מ - 77',
    yahavBank: 'בנק יהב לעובדי המדינה - 04',
    yovankBank: 'בנק של יובנק בע"מ - 26',
    pituhaTayasiaBank: 'בנק לפיתוח התעשייה - 07',
    sapanutBank: 'בנק הספנות לישראל - 08',
    yerushalayimBank: 'בנק ירושלים - 54',
    arabiIsraelBank: 'בנק של ערבי ישראל - 34',
    unitedEuroBank: 'בנק יורו טרייד - 01',
    haklautBank: 'בנק החלקאות לישראל - 19',
    citiBank: 'בנק סיטי - 22',
    kupatHaovedAlumiBank: 'בנק קופת העובד הלאומי - 48',
    otzarHashiltonBank: 'בנק אוצר השלטון המקומי - 68',
    daskiaBank: 'בנק דקסיה - 68',
    hasbcBank: 'בנק HSBC - 23',
    arabLandBank: 'Arab Land Bank - 67',
    bankOfIndiaBank: 'Bank of India - 39',

    bit: 'ביט bit',
    paybox: 'Paybox פייבוקס',
    pepper: 'Pepper פפר',
    chooseapplication: 'בחר אפליקציה',
    addpaymentlink: 'הוספת רשומת תשלום חדשה',
    mypayments: 'רשומות התשלום שברשותי',
    paidfor: 'שולם על',
    nopayments1: 'לא קיימים רשומות תשלום',
    nopayments2: 'כשיהיו, הן יופיעו כאן',
    dateOfPayment: 'תאריך התשלום',
    item: 'פריט',
    paymentType: 'שולם באמצעות',

    messagePaymentCreatedSuccess: 'רשומת התשלום נוצרה בהצלחה',
    isGizbar: 'האם גיזבר?',
    addStudentMessage: 'הודעה אישית - הרשמת תלמידים',
    messageSpecialMessageUpdatedSuccess: 'הודעה אישית עודכנה בהצלחה',
    specialMessage: 'הודעה אישית',
    editSpecialMessage: 'עריכת ההודעה האישית',
    editSpecialMessage2: 'הודעה זו תופיע בשליחת קישור להרשמה לתלמיד',
    addStudentMessagePayment: 'הערה קבועה למסמך כספי',
    paymentMessage: 'ההערה הקבועה למסמך כספי',
    editPaymentMessage: 'עריכת הודעה קבועה למסמך כספי',
    editPaymentMessage2: 'הודעה זו תופיע בשליחת קישור לרשומת תשלום חדשה',
    orsendlinkpayment: 'או לחילופין, שלחו לינק לתשלום',
    sendpaymentlink: 'לשליחת לינק לתשלום לתלמיד, לחצו כאן',
    sendoutsidepaymentlinkTItle: 'שליחת לינק תשלום לתלמיד',

    sendWhatsapp: 'שלחו חשבונית בווטצאפ',
    invoicereceiptandpaymentcreatedsuccess: 'רשומת התשלום וחשבונית מס קבלה נוצרו בהצלחה',
    backtohomepage: 'לחזרה לעמוד הבית, לחצו כאן',
    todownloadreceipt: 'להורדת הקבלה יש ללחוץ כאן',
    todownloadinvoice: 'להורדת החשבונית יש ללחוץ כאן',
    orH: 'או לחילופין',
    sendwhatsappreceipt: 'שלחו את החשבונית בווטצאפ לתלמיד בלחיצת כפתור',
    sendwhatsappreceiptteacher: 'שלחו את החשבונית בווטצאפ למורה בלחיצת כפתור',
    sendwhatsappreceiptInsideTest: 'שלחו את טופס המבחן פנימי לתלמיד בווטצאפ',
    sendwhatsappreceiptteacherInsideTest: 'שלחו את טופס המבחן פנימי למורה בווטצאפ',
    setInsideTest: 'קביעת מבחן פנימי',
    chooseproffesional: 'בחירת מנהל מקצועי אשר יעביר את המבחן',
    choosedateoftest: 'בחירת תאריך המבחן הפנימי',
    opencalendarheretime: 'לפתיחת השעון לחצו עליי',
    choosedateoftesttime: 'בחירת שעת המבחן',
    messageTestCreatedSuccess: 'מבחן פנימי נקבע בהצלחה',
    myweeklyAgenda: 'לוח המבחנים הפנימיים שלי',
    createDocument: 'הפק מסמך מבחן פנימי',
    not_ok: 'לא תקין',
    half_ok: 'תקין חלקית',
    inside_test_ok: 'תקין',
    inside_test_bad: 'הפקעת שליטה',
    passedHappy: 'עבר',
    notPassedSad: 'לא עבר',
    unkown: 'לא ידוע',
    insidetest_title: 'מבחן פנימי',
    emailaddress: 'כתובת מייל',
    changepwd: 'שנה סיסמה',
    passwordChangedSuccess: 'הסיסמה עודכנה בהצלחה',
    insidetestupdatedSuccessMessage: 'מהבחן הפנימי עודכן בהצלחה',
    cantpayanything: 'שימו לב: תלמיד זה לא יכול להרשם מכיוון שעבר את מכסת החיובים על האגרות הזמינות',
    dearstudentcantpay: 'תלמיד/ה יקר/ה, נראה שעברתם את מכסת התשלום על פריט זה, אנא צרו קשר עם המורה שלכם',
    all: 'הכל',
    cashregister: 'כסף בקופה',
    pullmoney: 'משכו כסף',
    mycashregister: 'הכסף בקופה שלי',
    transfermoney: 'העבר כסף',
    moneytransfersuccessmessage: 'הכסף הועבר בהצלחה',
    mycashregisterandtransfermoney: 'הקופה שלי ומשיכת כספים',
    canAddEmployees: 'האם יכול/ה להוסיף משתמשים',
    manageEmployees: 'ניהול העובדים',
    employeesinmyorg: 'העובדים בארגון שלי',
    employeesinmyorgstring1: 'אין עובדים לניהול בארגון שלך',
    employeesinmyorgstring2: 'כשיהיו - הם יופיעו כאן',
    mycommissioncashregister: 'קופת העמלות שלי:',
    generateinvoices: 'הפקת חשבוניות',
    studentname: 'שם התלמיד',
    callteacher: 'התקשר למורה',
    callstudent: 'התקשר לתלמיד',
    generateinvoice: 'הפק חשבונית',
    oldinvoices: 'חשבוניות שהופקו מתוך רשומות תשלום',
    nooldinvoices1: 'אין חשבוניות שכבר הופקו',
    nooldinvoices2: 'כשיהיו, הן יופיעו כאן',
    noinvoices1: 'אין חשבוניות להפקה',
    noinvoices2: 'כשיהיו, הן יופיעו כאן',
    invoiceready: 'החשבונית מוכנה',
    setWithoutdates: 'לחץ כאן לשליחה ללא תאריך ושעה',
    setDates: 'לחץ לקביעת תאריך המבחן פנימי',
    creditcardnumber: 'מספר כרטיס אשראי',
    cvvnumber: 'שלוש הספרות בגב הכרטיס (CVV)',
    expdatenumber: 'תאריך התפוגה',
    month: 'חודש',
    year: 'שנה',
    insidetestlist: 'לוח המבחנים הפנימיים',
    searchbydates: 'חיפוש ע"פי תאריכים',
    teacherportal: 'לחצו למעבר לתצוגת מורה / מנהל מקצועי',
    clerkportal: 'לחצו למעבר לתצוגת פקידים',
    writeacheque: "רישום תשלום למורה / מנהל מקצועי",
    nocheques1: "אין רשומות תשלום זמינות",
    nocheques2: "כשיהיו - הן יופיעו כאן",
    newcheque: "רשומת תשלום חדשה",
    choosenimanfromthelist: "בחר/י את העובד/ת מהרשימה",
    chequenum: "מספר הצ'ק",
    chequeamount: "סכום השיק ששולם",
    notmendatorycheque: "לא חובה, רק אם המורה הגיש/ה את החשבונית עבור התשלום",
    teacherinvoicenumber: 'מספר חשבונית המורה',
    teacherinvoiceamount: 'סכום חשבונית המורה',
    chequeamountnotequal: "סכום התשלום למורה, אינו תואם לחשבונית שהוגשה",
    pleasefillall: "אנא מלאו את כל הפרטים לפני יצירה",
    amountOnCheque: "הסכום ששולם",
    invoicecreated: "האם הוצאה חשבונית",
    teacherInvoiceNumber: "מספר החשבונית",
    teacherInvoiceAmount: "סכום על גבי החשבונית",
    attachinvoice: "הצמד חשבונית",
    canSeeCommission: 'האם יכול לראות קופת עמלות?',
    isbanktransferc: 'האם העברה בנקאית?',
    banktransferamount: 'הסכום ששולם',
    paymentsschool: 'רשומות התשלום של בית הספר',
    paymentsschoolteachers: 'רשומות התשלום למורים',
    paymentsschoolall: 'לצפייה בכל רשומות התשלום של בית הספר לחץ כאן',
    supplierslist: 'לרשימת הספקים',
    mysupplierslist: 'הספקים שלי',
    emptysuppliersstring1: 'אין ספקים עדיין',
    emptysuppliersstring2: 'כשיהיו - הם יופיעו כאן',
    addsupplier: 'הוספת ספק חדש',
    category: 'קטגוריה',
    suppliername: 'שם הספק',
    supplierphone: 'מספר הטלפון של הספק',
    searchbystudent: 'חיפוש על פי שם התלמיד',
    cantsetinsideteststudent: 'אין אפשרות לקבוע לתלמיד זה מבחן פנימי',
    ptorfrompayment: 'פטור מתשלום',
    ptor_reason: 'סיבת הפטור',
    automaticStudentApproval: 'אישור תלמידים אוטומטי',
    myweeklyAgendamystudents: 'המבחן פנימי של התלמידים שלי',
    manageItems: 'ניהול ומכירת פריטים',
    sellitem: 'מכירת פריט',
    itemname: 'שם הפריט / תיאור',
    makat: 'מק"ט',
    price: 'מחיר כולל מע"מ',
    searchstudentbynm: 'חפש תלמיד/ה לפי שם פרטי / שם משפחה / מספר טלפון / מספר ת.ז',

    /* POST SEND 23/09/20 */
    needtochooseteacherlicenses: 'עלייך לבחור מורה / מנהל מקצועי לפני שתוכל/י לבחור את סוג הרישיון',
    addStudentMessagePaymentPostSignup: 'הודעה שלאחר תשלום דמי רישום',
    addStudentMessagePaymentPostInsideTest: 'הודעה שלאחר תשלום דמי מבחן פנימי',
    supplierfee: 'אחוז מע"מ מוכר',
    suppliercanelsesee: 'הרשאת צפייה מחוץ למנהל',
    minimalageerror: 'התלמיד לא בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    minimalageerrorpersonal: 'אנו מצטערים, אינך בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    paidbeforedate: 'תאריך תשלום בעבר',
    padibeforeinvoice: 'מספר החשבונית',
    statusBrosh: 'סטטוס ברוש',
    didSignedWithBrosh: 'האם נרשם בברוש בעבר?',
    moneyTransferSettings: 'הרשאות משיכת כספים',
    canWithdrawMoney: 'האם יכול למשוך כספים? לחצו לשינוי',
    clientName: 'שם הלקוח',
    clientPhoneNumber: 'מספר הטלפון של הלקוח',
    messageSystem: 'מערכת ההודעות',
    emptymessagesstring1: 'אין הודעות ששידרתם',
    emptymessagesstring2: 'כשיהיו - הן יופיעו כאן',
    message: 'הודעה',
    broadcastat: 'שודר בתאריך',
    pause: 'השהיית הודעה',
    mymessages: 'ההודעות שלי',
    messagereceivedat: 'ההודעה התקבלה ב',
    canSeeInsideTests: 'מותר למשתמש לצפות בלוח המבחנים הפנימיים',
    canSellItems: 'יכול למכור פריטים',
    canTransferStudent: 'יכול להעביר תלמידים',
    statusBroshInsideTest: 'האם עודכן בברוש שעבר/ה מבחן פנימי',
    mymessagessivan: 'לוח מכירות/קניות קמע מחשבים',
    newmessageboardsivan: 'הוספת מודעה חדשה',
    refunds: 'זיכויים',
    refundables: 'הרשומות הניתנות לזיכוי',
    emptyrefundablesstring: 'אין רשומות הניתנות לזיכוי - כשיהיו, הן יופיעו כאן',
    canSetInsideTestsProfessional: 'האם מנהל מקצועי יכול לקבוע לעצמו מבחן פנימי',
    canGivePtorSignup: 'האם יכול לתת פטור מדמי רישום',
    canGivePtorInsideTest: 'האם יכול לתת פטור מבחן פנימי',
    mycashregisterandtransfermoneyasgizbar: 'הקופה שלי ומשיכת כספים - כגזבר',
    mycashregisterandtransfermoneyasteacher: 'הקופה שלי ומשיכת כספים - כמורה/מנהל מקצועי',
    canSetInsideTestsToOthers: 'יכול לקבוע מבחן פנימי למשתמשים אחרים',
    daily: 'יומי',
    weekly: 'שבועי',
    monthly: 'חודשי',
    other: 'אחר',
    rishumBabroshDates: 'רישום מבחן פנימי בברוש',
    canRemoveInsideTest: 'יכול להחזיר מבחן פנימי למאגר',
    canSetInsideTestsToOthersStudents: 'יכול לקבוע מבחן פנימי לתלמידים לא שלו (מנהל מקצועי/מורה/מנהל מקצועי)',
    canCollectCash: 'יכול לקבל מזומן',
    canCollectCheque: 'יכול לקבל שיק',
    canCollectBankTransfer: 'יכול לקבל העברה בנקאית',
    canCollectApplication: 'יכול לקבל העברה באפליקציית תשלומים',
    canCollectTranzila: 'יכול לקבל העברה בסליקה',
    canCollectBarter: 'יכול לבצע העברה בברטר',
    canDeposit: 'יכול לבצע הפקדות',
    bank: 'בנק',
    branch: 'סניף',
    branchNumber: 'סניף',
    bankAccountNumber: 'מספר חשבון',
    amount: 'סכום',
    depositaccounts: 'חשבונות הפקדה',
    deposit: 'הפקדה',
    choosedepositaccount: 'בחר חשבון להפקדה',
    account: 'חשבון',
    discoverPayments: 'מבט על - תשלומים',
    canDiscoverPayments: 'יכול לצפות במבט-על על תשלומים',
    canSeeTeachersCashRegister: 'צפה בקופות מורים',
    canEditInsideTests: 'יכול לערוך מבחנים פנימיים',
    canRefund: 'יכול לזכות תלמידים',
    canGivePtors: 'בעל הרשאה לתת פטורים',
    whoGaveThePtor: 'מי נתן הוראת הפטור',
    canSeePtorsGiven: 'יכול לצפות במעקב פטורים',
    /* 31/10/20 */
    canDoInsideTestsToMyStudents: 'יכול לעשות מבחן פנימי לתלמידים שלו',
    depositBy: 'הפקדה ע"י',
    teacherName: 'שם המורה',

    boardwithoutlogin: 'לכניסה ללוח המודעות ללא הרשמה, לחצו כאן',
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    productexp: 'תפוגת המוצר',
    subcategory: 'תת קטגוריה',
    buycost: 'מחיר עלות',
    wantedprice: 'מחיר מבוקש',
    freetext: 'טקסט חופשי',
    chooseproductimages: 'לחצו לבחירת תמונת המוצר',
    filechosen: 'הקובץ שנבחר',
    uploadtoboard: 'להעלאת המודעה לחצו כאן',
    pricesimple: 'מחיר',
    isproductexp: 'האם המוצר בעל תוקף?',
    code: 'קוד סודי הנשלח אליכם בSMS',
    paymentsdocument: 'הפקת מסמך כספי תקופתי'
  },
  ar: {
    myschool: 'مدرستي',
    myschools: 'مدرستي',
    school: 'مدرسة',
    main: 'رئيسي',
    studentsmanagement: 'إدارة الطلبة',
    myemployees: 'المعلمون،المدراء المهنيون والموظفين',
    employeename: 'اسم العامل',
    position: 'وظيفة',
    createdat: 'تاريخ الانشاءي',
    edit: 'تحرير',
    status: 'وضع',
    frozen: 'موقف',
    active: 'فعال',
    emptyemployeesstring1: 'عدم وجود معلمين وموظفين،اضغط',
    emptyemployeesstring2: 'إضافة معلم،مدير مهني تو موظف',
    addnewemployee: 'إضافة عامل جديد',
    firstname: 'الاسم الشخصي',
    lastname: 'اسم العائلة',
    phonenumber: 'تلفون',
    idnumber: 'هوية',
    email: 'ميل',
    type: 'نوع',
    teacher: 'معلم',
    professional: 'مدير مهني',
    professionalteacher: 'معلم/مدير مهني',
    clerk: 'رقم الإدارة المهنية',
    professionalnumber: 'رقم الإدارة المهنية',
    ispartner: 'مشارك؟',
    yes: 'نعم',
    no: 'ا',
    signuppaymentnumberofigrot: 'عدد وصولات رسوم التسجيل شهريا للمدرسة',
    insidetestpaymentnumberofigrot: 'عدد وصولات رسوم الامتحان الداخلي شهريا للمدرسة',
    signuppaymentcoststudent: 'تكاليف الامتحان الداخلي للطالب',
    insidetestpaymentcoststudent: 'مبلغ الامتحان الداخلي – وصل المعلم',
    signuppaymentcostteacher: 'تكاليف رسوم التسجيل للطالب',
    insidetestpaymentcostteacher: 'مبلغ رسوم التسجيل-وصل المعلم',
    password: 'كلمة السر',
    create: 'انشاء',
    update: 'حتلنة',
    editexistingemployee: 'تحرير مستعمل جديد',
    freezeemployee: 'توقيف عامل؟',
    addnewstudent: 'إضافة طالب جديد',
    birthday: 'تاريخ الولادة',
    gender: 'جنس',
    male: 'ذكر',
    female: 'انثى',
    othergender: 'يفضل عدم الذكر',
    licensetype: 'نوع الرخصة',
    allowedlicensetypelearn: 'أنواع الرخص المسموح فيها للتعليم',
    studentstatus: 'وضع الطالب',
    active: 'فعال',
    inactive: 'غير فعال',
    passedtest: 'نجد في التست العملي',
    cansignwithbrosh: 'يمكن تسجيله في "بروش"',
    broshsignupdate: 'تاريخ التسجيل في "بروش"',
    broshclerk: 'الموظف المسجل',
    paidsignupfee: 'دفع الرسوم',
    ptor: 'معفي',
    paidinsidetestfee: 'שילם מבחן פנימי',
    editschool: 'تحرير المدرسة',
    schoolname: 'اسم المدرسة',
    schooladdress: 'عنوان المدرسة',
    postalcode: 'رمز بريدي',
    schoolemail: 'ميل المدرسة',
    cpaemail: 'ميل المحاسب',
    cpe: 'المحاسب',
    officialidnumber: 'الرقم الرسمي',
    schooladdress: 'عنوان المراسلة',
    receipttaxinvoice: 'فاتورة ووصل استلام',
    invoicenumber: 'فاتورة',
    receipt: 'وصل استلام',
    editstudent: 'تحرير الطالب',
    deletestudent: 'الغاء الطالب',
    studentstatus: 'وضع الطالب',
    officename: 'اسم مكتب المحاسب',
    fax: 'فاكس',
    cpeaddress: 'عنوان المحاسب',
    cpepostalcode: 'الرمز البريدي للمحاسب',

    studentadd: 'إضافة طالب',
    getexternalstudentlink: 'رابط  لاضافة طالب خارجية  استلام',
    mystudents: 'طلابي',
    emptystudentstring1: 'ليس لدي طلاب-اضغط',
    emptystudentstring2: 'لاضافة طالب جديد',
    studentname: 'اسم الطالب',
    getexternalstudentlinktitle: 'طلاب للمصادقة-من رابط خارجي',
    nostudentstoapprove1: 'لا يوجد طلاب للمصادقة،عندما يكون-سيظهرون هنا',
    nostudentstoapprove2: 'لاضافة طالب جديد',
    delete: 'الغاء',
    approve: 'مصادقة',
    selfsignupthankyou: 'الطالب/ة،شكرا" على التعاون –نكون على اتصال',
    selfsigndearstudent: 'الطالب/ة العزيز/ة الرجاء تعبئة التفاصيل واضغطوا على زر المصادقة',
    selfsignattheend: 'أخيرا"، رسالة تصل الى المدرسة-شكرا" على التعاون',

    login: 'دخول',
    username: 'اسم المستعمل',

    teachername: 'اسم المعلم',

    licensetypeA1: 'A1 - אופנוע עד 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeA2: 'A2 - אופנוע עד 6.14 כוחות סוס ועד 11 קילוואט',
    licensetypeA: 'A - אופנוע שהספק מנועו עולה על 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeB: 'B ידני - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeB_: 'B אוטומט - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeC: 'C - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeC1: 'C1 - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ק"ג ועד 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeCA: 'C+E - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ,גם אם צמוד אליו נגרר או נתמך',
    licensetypeD: 'D - אוטובוס',
    licensetypeD1: 'D1 - מונית, רכב סיור ואוטובוס זעיר ציבורי במשקל כולל מותר עד 5,000 ק"ג ומספר מקומות ישיבה עד 16 נוסעים מלבד הנהג',

    migrate: 'تحويل طالب',
    migrateAction: 'حول طالب',
    chooseteacher: 'اختار المعلم',

    errorPleaseTryAgain: 'غلطة تقنية الرجاء حاول مرة أخرى.اذا بقيت المشكلة الرجاء التوجه لدعمنا.نعتذر لكم',
    messageCopiedSuccess: 'تم نسخ الرابط بنجاح',
    messageStudentUpdateSuccess: 'تم حتلنة الطالب بنجاح',
    messageSchoolCreatedSuccess: 'تم انشاء مدرسة جديدة بنجاح',
    messageLoggedOutSucces: 'تم تركك بنجاح',
    mustFillAllFields: 'يجب تعبئة كل الأماكن اللازمة',
    messageManagerUpdatedSuccess: 'المدير/ة حتلن/ت بنجاح',
    phoneNotGood: 'يجب تعبئة رقم  تلفون صحيح',
    idNumberNotGood: 'رقم الهوية غير صحيح',
    messageUserCreatedSuccess: 'تم انشاء المستعمل بنجاح',
    messageStudentAlreadyExists: 'الطالب موجود وتابع لمعلم',
    messageDearStudentAlreadyExists: 'أيها الطالب/ة ،يوجد حساب لكم عند المعلم:',
    messsageThankYouContactShortly: 'شكرا" جزيلا" ! نحاول التواصل معك قريبا"',
    messageUserUpdatedSuccess: 'تمت حتلنة المستعمل بنجاح',
    messageDeletionCancelled: 'تم محو الالغاء',
    messageSchoolUpdatedSuccess: 'تمت حتلنة المدرسة بنجاح',
    messageStudentDeletedSuccess: 'تم محو الطالب/ة بنجاح',
    canAddClerk: 'باستطاعتك إضافة موظف/ة',
    canBeChargedTwiceForSignupFee: 'هل بالإمكان دفع الطالب المذكور اكثر من مرة واحدة',
    chooseteacherAndShareLink: 'اختر/ي طالب وشارك/ي الرابط الرسمي',

    addnewstudentpayment: 'إضافة قائمة دفع اخرى',
    choosestudent: 'اختر/ي طالب من القائمة',
    opencalendarhere: 'اضغطوا هنا لفتح الرزنامة',
    choosedateofpayment: 'اختاروا تاريخ الدفع',
    paywith: 'الدفع بواسطة',

    cash: "نقدا",
    cheque: "شيك",
    invoiceDelivered: 'تم  تحرير الفاتورة',
    bankTransfer: 'تحويلة بنكية',
    creditcard: 'بطاقة اعتماد',
    application: 'تطبيق',
    tranzila: 'סליקה',
    barter: 'مقايض',
    ptor: 'معفي',
    amountWithVAT: 'المبلغ للدفع (شامل)',
    payfor: 'الدفع مقابل',
    signupFee: 'رسوم',
    signup: 'רישום',
    insideTestFee: 'رسوم الامتحان الداخلي',
    insideTest: 'מבחן פנימי',
    choosebank: 'اختار البنك',
    branchNumber: 'رقم الفرع',
    bankAccountNumber: 'رقم الحساب',

    bankLeumi: 'בנק לאומי - 10',
    bankIsrael: 'بنك إسرائيل - 99',
    bankHapoalim: 'بنك هبوعليم-12',
    discontBank: 'بنك ديسكونت-10',
    tfahotBank: ' بنك مزراحي طفاحوت-20',
    internationalBank: 'بنك بينليئومي ريشون-31',
    otzarHayalBank: 'بنك اوتسار هحيال-14',
    markantilDiscontBank: 'بنك مركنتيل ديسكونت-17',
    doarBank: 'بنك البريد-09',
    igudBank: 'بنك البريد-13',
    masadBank: 'بنك مساد-46',
    poaliAgudatBank: 'بنك بوعلي اغودات يسرائيل-52',
    mashkantaotDiscontBank: 'بنك ديسكونت لمشكنتؤوت-90',
    mashkantaotLeumiBank: 'بنك ليئومي لمشكنتؤوت-77',
    yahavBank: 'بنك ياهف -04',
    yovankBank: 'بنك يوفنك-26',
    pituhaTayasiaBank: 'بنك لتطوير الصناعة-07',
    sapanutBank: 'بنك البحارة لإسرائيل-08',
    yerushalayimBank: 'بنك يروشلايم-54',
    arabiIsraelBank: 'البنك العربي الإسرائيلي-34',
    unitedEuroBank: 'بنك يورو للتجارة-01',
    haklautBank: 'بنك الزراعة الإسرائيلي-19',
    citiBank: 'بنك سيتي-22',
    kupatHaovedAlumiBank: 'بنك صندوق العامل الوطني-48',
    otzarHashiltonBank: 'بنك جهاز الحكم المحلي-68',
    daskiaBank: 'بنك دسكيا-68',
    hasbcBank: 'بنك HSBC - 23',
    arabLandBank: 'بنك ارض العرب-67',
    bankOfIndiaBank: 'البنك الهندي-39',

    bit: 'ביט bit',
    paybox: 'Paybox פייבוקס',
    pepper: 'Pepper פפר',
    chooseapplication: 'اختيار التطبيق',
    addpaymentlink: 'إضافة قائمة دفع جديدة',
    mypayments: 'قائمة المدفوعات بحوزتي',
    paidfor: 'دفع مقابل',
    nopayments1: 'لا توجد قوائم دفع',
    nopayments2: 'ان وجدت، تظهر هنا',
    dateOfPayment: 'تاريخ الدفع',
    item: 'مادة',
    paymentType: 'دفع بواسطة',

    messagePaymentCreatedSuccess: 'قائمة الدفع انشات بنجاح',
    isGizbar: 'هل محاسب؟',
    addStudentMessage: 'رسالة شخصية-تسجيل طلاب',
    messageSpecialMessageUpdatedSuccess: 'رسالة شخصية حتلنت بنجاح',
    specialMessage: 'رسالة شخصية',
    editSpecialMessage: 'تحرير الرسالة الشخصية',
    editSpecialMessage2: 'هذه الرسالة تظهر عند ارسال الرابط للطالب',
    addStudentMessagePayment: 'ملاحظة ثابتة للتعهد المالي',
    paymentMessage: 'الملاحظة الثابتة للتعهد المالي',
    editPaymentMessage: 'تحرير رسالة ثابتة للتعهد المالي',
    editPaymentMessage2: 'هذه الملاحظة تظهر عند ارسال رابط التسجيل',
    orsendlinkpayment: 'او بدل ذلك،ارسلوا رابط الدفع',
    sendpaymentlink: 'ارسال رابط دفع للطالب، اضغطوا هنا',
    sendoutsidepaymentlinkTItle: 'ارسال رابط دفع للطالب',

    sendWhatsapp: 'ارسلوا فاتورة بالواتس',
    invoicereceiptandpaymentcreatedsuccess: 'قائمة الدفع وفاتورة الضريبة  بنجاح   تم انشاء',
    backtohomepage: 'للرجوع الى صفحة البداية اضغطوا هنا',
    todownloadreceipt: 'انزال الوصل اضغط هنا',
    todownloadinvoice: 'لانزال الفاتورة اضغط هنا',
    orH: 'او بدل ذلك',
    sendwhatsappreceipt: 'ارسال  الفاتورة بالواتس للطالب ،بالغط على الزر',
    sendwhatsappreceiptteacher: 'ارسال الفاتورة بالواتس الى المعلم بالضغط على الزر',
    sendwhatsappreceiptInsideTest: 'ارسلوا نموذج מבחן פנימי..  للطالب بالواتس',
    sendwhatsappreceiptteacherInsideTest: 'ارسال النموذج للمعلم بالواتس',
    setInsideTest: 'تعيين امتحان داخلي',
    chooseproffesional: 'اختيار المدير المهني للامتحان',
    choosedateoftest: 'اختيار تاريخ الامتحان الداخلي',
    opencalendarheretime: 'لفتح الساعة اضغط هنا',
    choosedateoftesttime: 'اختيار ساعة الامتحان',
    messageTestCreatedSuccess: 'تم تعيين الامتحان الداخلي بنجاح',
    myweeklyAgenda: 'قائمة امتحاناتي الداخلية',
    createDocument: 'اخرج نموذج מבחן פנימי.',
    not_ok: 'غير صحيح',
    half_ok: 'صحيح جزئيا',
    inside_test_ok: 'صحيح',
    inside_test_bad: 'تولي السيطرة',
    passedHappy: 'نجح',
    notPassedSad: 'لم ينجح',
    unkown: 'غير معروف',
    insidetest_title: 'امتحان داخلي',
    emailaddress: 'عنوان البريد الالكتروني',
    changepwd: 'غير كلمة السر',
    passwordChangedSuccess: 'تغيير كلمة السر تمت بنجاح',
    insidetestupdatedSuccessMessage: 'حتلنة نتيجة الامتحان الداخلي تمت بنجاح',
    cantpayanything: 'انتبه،هذا الطالب لا يستطيع التسجيل بسبب تجاوزه الحد الأقصى لديونه على وصل الدفع ووصل מבחן פנימי. الرجاء التواصل مع المعلم',
    dearstudentcantpay: 'الطالب/ة يبدو انكم تجاوزتم الحد الأقصى لعدد الدروس على هذه المادة،الرجاء التواصل مع المعلم',
    all: 'الجميع',
    cashregister: 'مبلغ بالصندوق',
    pullmoney: 'اسحبوا النقود',
    mycashregister: 'النقود في صندوقي',
    transfermoney: 'حول النقود',
    moneytransfersuccessmessage: 'تم تحويل النقود بنجاح',
    mycashregisterandtransfermoney: 'صندوقي وسحب النقود',
    canAddEmployees: 'هل بالإمكان زيادة مستعملين',
    manageEmployees: 'إدارة العمال',
    employeesinmyorg: 'العمال داخل مؤسستي',
    employeesinmyorgstring1: 'لا يوجد عمال للإدارة بداخل المؤسسة',
    employeesinmyorgstring2: 'في حال وجدوا يظهروا هنا',
    mycommissioncashregister: 'صندوق العمالة',
    generateinvoices: 'اخراج فواتير',
    studentname: 'اسم الطالب',
    callteacher: 'اتصل بالمعلم',
    callstudent: 'اتصل بالطالب',
    generateinvoice: 'انتج فاتورة',
    oldinvoices: 'فواتير انتجت من قوائم الدفع',
    nooldinvoices1: 'لا توجد فواتير تم انتاحها',
    nooldinvoices2: 'في حال وجدت ، تظهر هنا',
    noinvoices1: 'لا يوجد فواتير للاخراج',
    noinvoices2: 'ان وجدوا، يظهروا هنا',
    invoiceready: 'الفاتورة جاهزة',
    setWithoutdates: 'اضغط هنا،للارسال بدون تاريخ وساعة',
    setDates: 'اضغط لتعيين تاريخ מבחן פנימי.',
    creditcardnumber: 'عدد بطاقات الاعتماد',
    cvvnumber: 'ثلاث ارقام في ظهر البطاقة (CVV)',
    expdatenumber: 'تاريخ الانتهاء',
    month: 'شهر',
    year: 'سنة',
    insidetestlist: 'لائحة الامتحانات الدالخلية',
    searchbydates: 'تفتيش حسب التواريخ',
    teacherportal: 'اضغط للتحول لشاشة المعلم/المدير المهني',
    clerkportal: 'اضغط للتحول لشاشة الموظفين',
    writeacheque: "تسجيل دفع للمعلم/للمدير المهني",
    nocheques1: "لا توجد قوائم دفع جاهزة",
    nocheques2: "في حال وجدت، تظهر هنا",
    newcheque: "قوائم دفع جاهزة",
    choosenimanfromthelist: "اختر/ي العامل/ة من القائمة",
    chequenum: "نمرة الشيك",
    chequeamount: "مبلغ الشيك المدفوع",
    notmendatorycheque: "غير ضروري،فقط في حالة قدم المعلم الفاتورة للدفع",
    teacherinvoicenumber: 'رقم فاتورة المعلم',
    teacherinvoiceamount: 'مبلغ فاتورة المعلم',
    chequeamountnotequal: "مبلغ الدفع للمعلم،لا يلائم الفاتورة المقدمة",
    pleasefillall: "الرجاء تعبئة كل التفاصبل قبل الاخراج",
    amountOnCheque: "المبلغ المدفوع",
    invoicecreated: "هل تم اخراج فاتورة",
    teacherInvoiceNumber: "رقم الفاتورة",
    teacherInvoiceAmount: "المبلغ على ظهر الفاتورة",
    attachinvoice: "ارفق فاتورة",
    canSeeCommission: 'هل بالإمكان مشاهدة صندوق العمولة',
    isbanktransferc: 'هل حوالة بنكية؟',
    banktransferamount: 'المبلغ المدفوع',
    paymentsschool: 'قوائم دفع المدرسة',
    paymentsschoolteachers: 'قوائم الدفع للمعلمين',
    paymentsschoolall: 'لمشاهدة كل قوائم دفع المدرسة ،اضغط هنا',
    supplierslist: 'لقوائم الوكلاء',
    mysupplierslist: 'وكلائي',
    emptysuppliersstring1: 'لا يوجد وكلاء حتى الان',
    emptysuppliersstring2: 'ان وجدوا ، يظهروا هنا',
    addsupplier: 'הוספת ספק חדש',
    category: 'קטגוריה',
    suppliername: 'שם הספק',
    supplierphone: 'تلفون المزود',
    searchbystudent: 'تفتيش حسب اسم الطالب',
    cantsetinsideteststudent: 'من غير الممكن تعيين מבחן פנימי.  لهذا الطالب',
    ptorfrompayment: 'معفي من الدفع',
    ptor_reason: 'سبب الاعفاء',
    automaticStudentApproval: 'مصادقة طلاب اوتوماتيكية',
    myweeklyAgendamystudents: 'ה מבחן פנימי. لطلابي',
    manageItems: 'إدارة وبيع مواد',
    sellitem: 'بيع مادة',
    itemname: 'اسم المادة',
    makat: 'מק"ט',
    price: 'السعر الشامل',
    searchstudentbynm: 'فتش طالب/ة حسب الاسم الشخصي/اسم العائلة/رثم التلفون/رقم الهوية',
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    productexp: 'תפוגת המוצר',
    subcategory: 'תת קטגוריה',
    buycost: 'מחיר עלות',
    wantedprice: 'מחיר מבוקש',
    freetext: 'טקסט חופשי',
    chooseproductimages: 'לחצו לבחירת תמונת המוצר',
    filechosen: 'הקובץ שנבחר',
    uploadtoboard: 'להעלאת המודעה לחצו כאן',
    pricesimple: 'מחיר',

    /* POST SEND 23/09/20 */
    needtochooseteacherlicenses: 'עלייך לבחור מורה / מנהל מקצועי לפני שתוכל/י לבחור את סוג הרישיון',
    addStudentMessagePaymentPostSignup: 'הודעה שלאחר תשלום דמי רישום',
    addStudentMessagePaymentPostInsideTest: 'הודעה שלאחר תשלום דמי מבחן פנימי',
    supplierfee: 'אחוז מע"מ מוכר',
    suppliercanelsesee: 'הרשאת צפייה מחוץ למנהל',
    minimalageerror: 'התלמיד לא בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    minimalageerrorpersonal: 'אנו מצטערים, אינך בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    paidbeforedate: 'תאריך תשלום בעבר',
    padibeforeinvoice: 'מספר החשבונית',
    statusBrosh: 'סטטוס ברוש',
    didSignedWithBrosh: 'האם נרשם בברוש בעבר?',
    moneyTransferSettings: 'הרשאות משיכת כספים',
    canWithdrawMoney: 'האם יכול למשוך כספים? לחצו לשינוי',
    clientName: 'שם הלקוח',
    clientPhoneNumber: 'מספר הטלפון של הלקוח',
    messageSystem: 'מערכת ההודעות',
    emptymessagesstring1: 'אין הודעות ששידרתם',
    emptymessagesstring2: 'כשיהיו - הן יופיעו כאן',
    message: 'הודעה',
    broadcastat: 'שודר בתאריך',
    pause: 'השהיית הודעה',
    mymessages: 'ההודעות שלי',
    messagereceivedat: 'ההודעה התקבלה ב',
    canSeeInsideTests: 'מותר למשתמש לצפות בלוח המבחנים הפנימיים',
    canSellItems: 'יכול למכור פריטים',
    canTransferStudent: 'יכול להעביר תלמידים',
    statusBroshInsideTest: 'האם עודכן בברוש שעבר/ה מבחן פנימי',
    mymessagessivan: 'לוח מכירות/קניות קמע מחשבים',
    newmessageboardsivan: 'הוספת מודעה חדשה',
    refunds: 'זיכויים',
    refundables: 'הרשומות הניתנות לזיכוי',
    emptyrefundablesstring: 'אין רשומות הניתנות לזיכוי - כשיהיו, הן יופיעו כאן',
    canSetInsideTestsProfessional: 'האם מנהל מקצועי יכול לקבוע לעצמו מבחן פנימי',
    canGivePtorSignup: 'האם יכול לתת פטור מדמי רישום',
    canGivePtorInsideTest: 'האם יכול לתת פטור מבחן פנימי',
    mycashregisterandtransfermoneyasgizbar: 'הקופה שלי ומשיכת כספים - כגזבר',
    mycashregisterandtransfermoneyasteacher: 'הקופה שלי ומשיכת כספים - כמורה/מנהל מקצועי',
    canSetInsideTestsToOthers: 'יכול לקבוע מבחן פנימי למשתמשים אחרים',
    daily: 'יומי',
    weekly: 'שבועי',
    monthly: 'חודשי',
    other: 'אחר',
    rishumBabroshDates: 'רישום מבחן פנימי בברוש',
    canRemoveInsideTest: 'יכול להחזיר מבחן פנימי למאגר',
    canSetInsideTestsToOthersStudents: 'יכול לקבוע מבחן פנימי לתלמידים לא שלו (מנהל מקצועי/מורה/מנהל מקצועי)',
    canCollectCash: 'יכול לקבל מזומן',
    canCollectCheque: 'יכול לקבל שיק',
    canCollectBankTransfer: 'יכול לקבל העברה בנקאית',
    canCollectApplication: 'יכול לקבל העברה באפליקציית תשלומים',
    canCollectTranzila: 'יכול לקבל העברה בסליקה',
    canCollectBarter: 'יכול לבצע העברה בברטר',
    canDeposit: 'יכול לבצע הפקדות',
    bank: 'בנק',
    branch: 'סניף',
    branchNumber: 'סניף',
    bankAccountNumber: 'מספר חשבון',
    amount: 'סכום',
    depositaccounts: 'חשבונות הפקדה',
    deposit: 'הפקדה',
    choosedepositaccount: 'בחר חשבון להפקדה',
    account: 'חשבון',
    discoverPayments: 'מבט על - תשלומים',
    canDiscoverPayments: 'יכול לצפות במבט-על על תשלומים',
    canSeeTeachersCashRegister: 'צפה בקופות מורים',
    canEditInsideTests: 'יכול לערוך מבחנים פנימיים',
    canRefund: 'יכול לזכות תלמידים',
    canGivePtors: 'בעל הרשאה לתת פטורים',
    whoGaveThePtor: 'מי נתן הוראת הפטור',
    canSeePtorsGiven: 'יכול לצפות במעקב פטורים',

    boardwithoutlogin: "لتسجيل الدخول إلى لوحة الإعلانات بدون تسجيل ، انقر هنا",
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    productexp: 'תפוגת המוצר',
    subcategory: 'תת קטגוריה',
    buycost: 'מחיר עלות',
    wantedprice: 'מחיר מבוקש',
    freetext: 'טקסט חופשי',
    chooseproductimages: 'לחצו לבחירת תמונת המוצר',
    filechosen: 'הקובץ שנבחר',
    uploadtoboard: 'להעלאת המודעה לחצו כאן',
    pricesimple: 'מחיר',
    isproductexp: 'האם המוצר בעל תוקף?',
    paymentsdocument: 'הפקת מסמך כספי תקופתי'
  },
  ru: {
    myschool: 'בית הספר שלי',
    myschools: 'בתי הספר שלי',
    school: 'בית ספר',
    main: 'ראשי',
    studentsmanagement: 'ניהול התלמידים',
    myemployees: 'המורים, המנהלים המקצועיים והפקידים שלי',
    employeename: 'שם העובד',
    position: 'תפקיד',
    createdat: 'נוצר בתאריך',
    edit: 'עריכה',
    status: 'סטטוס',
    frozen: 'מושהה',
    active: 'פעיל',
    emptyemployeesstring1: 'אין לך מורים או פקידים, אנא לחץ על',
    emptyemployeesstring2: 'להוספת מורה, מנהל מקצועי או פקיד',
    addnewemployee: 'הוספת עובד חדש',
    firstname: 'שם פרטי',
    lastname: 'שם משפחה',
    phonenumber: 'מספר טלפון',
    idnumber: 'ת.ז',
    email: 'אימייל',
    type: 'סוג',
    teacher: 'מורה',
    professional: 'מנהל מקצועי',
    professionalteacher: 'מורה/מנהל מקצועי',
    clerk: 'פקיד/ה',
    professionalnumber: 'מספר הנהלה מקצועית',
    ispartner: 'האם שותף',
    yes: 'כן',
    no: 'לא',
    signuppaymentnumberofigrot: 'דמי רישום מספר אגרות חודשי לבית הספר',
    insidetestpaymentnumberofigrot: 'מבחן פנימי מספר אגרות חודשי לבית הספר',
    signuppaymentcoststudent: 'סכום למבחן פנימי - עלות לתלמיד',
    insidetestpaymentcoststudent: 'סכום למבחן פנימי - תקבול המורה',
    signuppaymentcostteacher: 'סכום לדמי רישום - עלות לתלמיד',
    insidetestpaymentcostteacher: 'סכום לדמי רישום - תקבול המורה',
    password: 'סיסמה',
    create: 'יצירה',
    update: 'עדכון',
    editexistingemployee: 'עריכת משתמש קיים',
    freezeemployee: '?הקפאת עובד',
    addnewstudent: 'הוספת תלמיד חדש',
    birthday: 'תאריך לידה',
    gender: 'מגדר',
    male: 'זכר',
    female: 'נקבה',
    othergender: 'מעדיפים לא לציין',
    licensetype: 'סוג רשיון',
    allowedlicensetypelearn: 'סוגי רשיון מותרים ללימוד',
    studentstatus: 'סטטוס התלמיד',
    active: 'פעיל',
    inactive: 'לא פעיל',
    passedtest: 'עבר טסט',
    cansignwithbrosh: 'רשאי/ת להרשם בברוש',
    broshsignupdate: 'תאריך רישום בברוש',
    broshclerk: 'הפקיד הרושם',
    paidsignupfee: 'שילם דמי רישום',
    ptor: 'פטור',
    paidinsidetestfee: 'שילם מבחן פנימי',
    editschool: 'עריכת בית הספר',
    schoolname: 'שם בית הספר',
    schooladdress: 'כתובת בית הספר',
    postalcode: 'מיקוד',
    schoolemail: 'אי-מייל בית הספר',
    cpaemail: 'אי-מייל רואה חשבון',
    cpe: 'רואה חשבון',
    officialidnumber: 'ע.מ/ע.פ/ח.פ',
    schooladdress: 'כתובת למשלוח דואר',
    receipttaxinvoice: 'חשבונית מס קבלה',
    invoicenumber: 'חשבונית',
    receipt: 'קבלה',
    editstudent: 'עריכת תלמיד/ה',
    deletestudent: 'מחיקת התלמיד/ה',
    studentstatus: 'סטטוס התלמיד',
    officename: 'שם המשרד (רו"ח)',
    fax: 'מספר פקס',
    cpeaddress: 'כתובת משרד הרו"ח',
    cpepostalcode: 'מיקוד משרד הרו"ח',

    studentadd: 'הוספת תלמיד/ה',
    getexternalstudentlink: 'קבלת קישור להוספת תלמיד חיצונית',
    mystudents: 'התלמידים שלי',
    emptystudentstring1: 'אין לך תלמידים, אנא לחץ על',
    emptystudentstring2: 'להוספת תלמיד חדש',
    studentname: 'שם התלמיד',
    getexternalstudentlinktitle: 'תלמידים לאישור - מלינק חיצוני',
    nostudentstoapprove1: 'אין תלמידים לאישור. כשיהיו - הם יופיעו כאן',
    nostudentstoapprove2: 'להוספת תלמיד חדש',
    delete: 'מחיקה',
    approve: 'אישור',
    selfsignupthankyou: '!תלמיד/ה יקר/ה, תודה רבה על שיתוף הפעולה. ניצור איתך קשר בהקדם',
    selfsigndearstudent: 'תלמיד/ה יקר/ה, אנא מלאו את הפרטים ולחצו על כפתור האישור',
    selfsignattheend: 'בסיום, הודעה תשלח לבית הספר. מודה לכם על שיתוף הפעולה,',

    login: 'התחברות',
    username: 'שם משתמש',

    teachername: 'שם המורה',

    licensetypeA1: 'A1 - אופנוע עד 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeA2: 'A2 - אופנוע עד 6.14 כוחות סוס ועד 11 קילוואט',
    licensetypeA: 'A - אופנוע שהספק מנועו עולה על 46.47 כוחות סוס) 35 קילוואט)',
    licensetypeB: 'B ידני - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeB_: 'B אוטומט - רכב מנועי במשקל כולל מותר עד 3,500 ק"ג, רשאי להסיע עד 8 נוסעים פרט לנהג, גם כאשר גורר נגרר במשקל עד 1,500 ק"ג',
    licensetypeC: 'C - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeC1: 'C1 - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ק"ג ועד 12,000 ק"ג, גם כאשר גורר נגרר במשקל כולל מותר עד 3,500 ק"ג',
    licensetypeCA: 'C+E - רכב מנועי מסחרי ורכב עבודה במשקל כולל מותר מעל 3,500 ,גם אם צמוד אליו נגרר או נתמך',
    licensetypeD: 'D - אוטובוס',
    licensetypeD1: 'D1 - מונית, רכב סיור ואוטובוס זעיר ציבורי במשקל כולל מותר עד 5,000 ק"ג ומספר מקומות ישיבה עד 16 נוסעים מלבד הנהג',

    migrate: 'מעבר תלמיד/ה',
    migrateAction: 'העבר תלמיד/ה',
    chooseteacher: 'בחר מורה / מנהל מקצועי',

    errorPleaseTryAgain: 'שגיאה בחיבור לשרת, אנא נסו בשנית. אם הבעיה חוזרת על עצמה - יש לפנות לתמיכה שלנו. סליחה על האי נוחות הזמנית.',
    messageCopiedSuccess: 'הקישור הועתק בהצלחה',
    messageStudentUpdateSuccess: 'התלמיד עודכן בהצלחה',
    messageSchoolCreatedSuccess: 'בית ספר חדש נוצר בהצלחה',
    messageLoggedOutSucces: 'התנתקת בהצלחה',
    mustFillAllFields: 'עלייך למלא את כל שדות החובה',
    messageManagerUpdatedSuccess: 'המנהל/ת עודכנ/ה בהצלחה',
    phoneNotGood: 'יש למלא מספר טלפון תקני',
    idNumberNotGood: 'מספר תעודת זהות אינו תקני',
    messageUserCreatedSuccess: 'המשתמש נוצר בהצלחה',
    messageStudentAlreadyExists: 'התלמיד כבר קיים ושייך למורה',
    messageDearStudentAlreadyExists: 'תלמיד/ה יקר/ה, נראה שחשבונכם כבר קיים אצל המורה:',
    messsageThankYouContactShortly: 'תודה רבה! ניצור עימך קשר בהקדם',
    messageUserUpdatedSuccess: 'המשתמש עודכן בהצלחה',
    messageDeletionCancelled: 'המחיקה בוטלה',
    messageSchoolUpdatedSuccess: 'בית הספר עודכן בהצלחה',
    messageStudentDeletedSuccess: 'התלמיד/ה נמחק/ה בהצלחה',
    canAddClerk: 'יכול/ה להוסיף פקיד/ה',
    canBeChargedTwiceForSignupFee: 'האם ניתן לחייב תלמיד זה יותר מפעם אחת על דמי רישום',
    chooseteacherAndShareLink: 'בחר/י מורה ושתפ/י את הלינק הרשמה שלו לתלמידים',

    addnewstudentpayment: 'הוספת רשומת תשלום חדשה',
    choosestudent: 'בחר/י תלמיד מהרשימה',
    opencalendarhere: 'לחצו עליי לפתיחת הלוח שנה',
    choosedateofpayment: 'בחרו את תאריך התשלום',
    paywith: 'תשלום באמצעות',

    cash: "מזומן",
    cheque: "שיק",
    invoiceDelivered: 'חשבונית הופקה',
    bankTransfer: 'העברה בנקאית',
    creditcard: 'כרטיס אשראי',
    application: 'אפליקצייה',
    tranzila: 'סליקה',
    barter: 'ברטר',
    ptor: 'פטור',
    amountWithVAT: 'סכום לתשלום (כולל מע"מ)',
    payfor: 'תשלום עבור',
    signupFee: 'דמי רישום',
    signup: 'רישום',
    insideTestFee: 'דמי מבחן פנימי',
    insideTest: 'מבחן פנימי',
    choosebank: 'בחר בנק',
    branchNumber: 'מספר סניף',
    bankAccountNumber: 'מספר חשבון בנק',

    bankLeumi: 'בנק לאומי - 10',
    bankIsrael: 'בנק ישראל - 99',
    bankHapoalim: 'בנק הפועלים - 12',
    discontBank: 'בנק דיסקונט - 11',
    tfahotBank: 'בנק מזרחי טפחות - 20',
    internationalBank: 'בנק הבינלאומי הראשון - 31',
    otzarHayalBank: 'בנק אוצר החייל - 14',
    markantilDiscontBank: 'בנק מרכנתיל דיסקונט - 17',
    doarBank: 'בנק הדואר - 09',
    igudBank: 'בנק איגוד - 13',
    masadBank: 'בנק מסד - 46',
    poaliAgudatBank: 'בנק פועלי אגודת ישראל - 52',
    mashkantaotDiscontBank: 'בנק דיסקונט למשכנתאות - 90',
    mashkantaotLeumiBank: 'בנק לאומי למשכנתאות בע"מ - 77',
    yahavBank: 'בנק יהב לעובדי המדינה - 04',
    yovankBank: 'בנק של יובנק בע"מ - 26',
    pituhaTayasiaBank: 'בנק לפיתוח התעשייה - 07',
    sapanutBank: 'בנק הספנות לישראל - 08',
    yerushalayimBank: 'בנק ירושלים - 54',
    arabiIsraelBank: 'בנק של ערבי ישראל - 34',
    unitedEuroBank: 'בנק יורו טרייד - 01',
    haklautBank: 'בנק החלקאות לישראל - 19',
    citiBank: 'בנק סיטי - 22',
    kupatHaovedAlumiBank: 'בנק קופת העובד הלאומי - 48',
    otzarHashiltonBank: 'בנק אוצר השלטון המקומי - 68',
    daskiaBank: 'בנק דקסיה - 68',
    hasbcBank: 'בנק HSBC - 23',
    arabLandBank: 'Arab Land Bank - 67',
    bankOfIndiaBank: 'Bank of India - 39',

    bit: 'ביט bit',
    paybox: 'Paybox פייבוקס',
    pepper: 'Pepper פפר',
    chooseapplication: 'בחר אפליקציה',
    addpaymentlink: 'הוספת רשומת תשלום חדשה',
    mypayments: 'רשומות התשלום שברשותי',
    paidfor: 'שולם על',
    nopayments1: 'לא קיימים רשומות תשלום',
    nopayments2: 'כשיהיו, הן יופיעו כאן',
    dateOfPayment: 'תאריך התשלום',
    item: 'פריט',
    paymentType: 'שולם באמצעות',

    messagePaymentCreatedSuccess: 'רשומת התשלום נוצרה בהצלחה',
    isGizbar: 'האם גיזבר?',
    addStudentMessage: 'הודעה אישית - הרשמת תלמידים',
    messageSpecialMessageUpdatedSuccess: 'הודעה אישית עודכנה בהצלחה',
    specialMessage: 'הודעה אישית',
    editSpecialMessage: 'עריכת ההודעה האישית',
    editSpecialMessage2: 'הודעה זו תופיע בשליחת קישור להרשמה לתלמיד',
    addStudentMessagePayment: 'הערה קבועה למסמך כספי',
    paymentMessage: 'ההערה הקבועה למסמך כספי',
    editPaymentMessage: 'עריכת הודעה קבועה למסמך כספי',
    editPaymentMessage2: 'הודעה זו תופיע בשליחת קישור לרשומת תשלום חדשה',
    orsendlinkpayment: 'או לחילופין, שלחו לינק לתשלום',
    sendpaymentlink: 'לשליחת לינק לתשלום לתלמיד, לחצו כאן',
    sendoutsidepaymentlinkTItle: 'שליחת לינק תשלום לתלמיד',

    sendWhatsapp: 'שלחו חשבונית בווטצאפ',
    invoicereceiptandpaymentcreatedsuccess: 'רשומת התשלום וחשבונית מס קבלה נוצרו בהצלחה',
    backtohomepage: 'לחזרה לעמוד הבית, לחצו כאן',
    todownloadreceipt: 'להורדת הקבלה יש ללחוץ כאן',
    todownloadinvoice: 'להורדת החשבונית יש ללחוץ כאן',
    orH: 'או לחילופין',
    sendwhatsappreceipt: 'שלחו את החשבונית בווטצאפ לתלמיד בלחיצת כפתור',
    sendwhatsappreceiptteacher: 'שלחו את החשבונית בווטצאפ למורה בלחיצת כפתור',
    sendwhatsappreceiptInsideTest: 'שלחו את טופס המבחן פנימי לתלמיד בווטצאפ',
    sendwhatsappreceiptteacherInsideTest: 'שלחו את טופס המבחן פנימי למורה בווטצאפ',
    setInsideTest: 'קביעת מבחן פנימי',
    chooseproffesional: 'בחירת מנהל מקצועי אשר יעביר את המבחן',
    choosedateoftest: 'בחירת תאריך המבחן הפנימי',
    opencalendarheretime: 'לפתיחת השעון לחצו עליי',
    choosedateoftesttime: 'בחירת שעת המבחן',
    messageTestCreatedSuccess: 'מבחן פנימי נקבע בהצלחה',
    myweeklyAgenda: 'לוח המבחנים הפנימיים שלי',
    createDocument: 'הפק מסמך מבחן פנימי',
    not_ok: 'לא תקין',
    half_ok: 'תקין חלקית',
    inside_test_ok: 'תקין',
    inside_test_bad: 'הפקעת שליטה',
    passedHappy: 'עבר',
    notPassedSad: 'לא עבר',
    unkown: 'לא ידוע',
    insidetest_title: 'מבחן פנימי',
    emailaddress: 'כתובת מייל',
    changepwd: 'שנה סיסמה',
    passwordChangedSuccess: 'הסיסמה עודכנה בהצלחה',
    insidetestupdatedSuccessMessage: 'מהבחן הפנימי עודכן בהצלחה',
    cantpayanything: 'שימו לב: תלמיד זה לא יכול להרשם מכיוון שעבר את מכסת החיובים על אגרת הרשמה ועל אגרת מבחן פנימי',
    dearstudentcantpay: 'תלמיד/ה יקר/ה, נראה שעברתם את מכסת התשלום על פריט זה, אנא צרו קשר עם המורה שלכם',
    all: 'הכל',
    cashregister: 'כסף בקופה',
    pullmoney: 'משכו כסף',
    mycashregister: 'הכסף בקופה שלי',
    transfermoney: 'העבר כסף',
    moneytransfersuccessmessage: 'הכסף הועבר בהצלחה',
    mycashregisterandtransfermoney: 'הקופה שלי ומשיכת כספים',
    canAddEmployees: 'האם יכול/ה להוסיף משתמשים',
    manageEmployees: 'ניהול העובדים',
    employeesinmyorg: 'העובדים בארגון שלי',
    employeesinmyorgstring1: 'אין עובדים לניהול בארגון שלך',
    employeesinmyorgstring2: 'כשיהיו - הם יופיעו כאן',
    mycommissioncashregister: 'קופת העמלות שלי:',
    generateinvoices: 'הפקת חשבוניות',
    studentname: 'שם התלמיד',
    callteacher: 'התקשר למורה',
    callstudent: 'התקשר לתלמיד',
    generateinvoice: 'הפק חשבונית',
    oldinvoices: 'חשבוניות שהופקו מתוך רשומות תשלום',
    nooldinvoices1: 'אין חשבוניות שכבר הופקו',
    nooldinvoices2: 'כשיהיו, הן יופיעו כאן',
    noinvoices1: 'אין חשבוניות להפקה',
    noinvoices2: 'כשיהיו, הן יופיעו כאן',
    invoiceready: 'החשבונית מוכנה',
    setWithoutdates: 'לחץ כאן לשליחה ללא תאריך ושעה',
    setDates: 'לחץ לקביעת תאריך המבחן פנימי',
    creditcardnumber: 'מספר כרטיס אשראי',
    cvvnumber: 'שלוש הספרות בגב הכרטיס (CVV)',
    expdatenumber: 'תאריך התפוגה',
    month: 'חודש',
    year: 'שנה',
    insidetestlist: 'לוח המבחנים הפנימיים',
    searchbydates: 'חיפוש ע"פי תאריכים',
    teacherportal: 'לחצו למעבר לתצוגת מורה / מנהל מקצועי',
    clerkportal: 'לחצו למעבר לתצוגת פקידים',
    writeacheque: "רישום תשלום למורה / מנהל מקצועי",
    nocheques1: "אין רשומות תשלום זמינות",
    nocheques2: "כשיהיו - הן יופיעו כאן",
    newcheque: "רשומת תשלום חדשה",
    choosenimanfromthelist: "בחר/י את העובד/ת מהרשימה",
    chequenum: "מספר הצ'ק",
    chequeamount: "סכום השיק ששולם",
    notmendatorycheque: "לא חובה, רק אם המורה הגיש/ה את החשבונית עבור התשלום",
    teacherinvoicenumber: 'מספר חשבונית המורה',
    teacherinvoiceamount: 'סכום חשבונית המורה',
    chequeamountnotequal: "סכום התשלום למורה, אינו תואם לחשבונית שהוגשה",
    pleasefillall: "אנא מלאו את כל הפרטים לפני יצירה",
    amountOnCheque: "הסכום ששולם",
    invoicecreated: "האם הוצאה חשבונית",
    teacherInvoiceNumber: "מספר החשבונית",
    teacherInvoiceAmount: "סכום על גבי החשבונית",
    attachinvoice: "הצמד חשבונית",
    canSeeCommission: 'האם יכול לראות קופת עמלות?',
    isbanktransferc: 'האם העברה בנקאית?',
    banktransferamount: 'הסכום ששולם',
    paymentsschool: 'רשומות התשלום של בית הספר',
    paymentsschoolteachers: 'רשומות התשלום למורים',
    paymentsschoolall: 'לצפייה בכל רשומות התשלום של בית הספר לחץ כאן',
    supplierslist: 'לרשימת הספקים',
    mysupplierslist: 'הספקים שלי',
    emptysuppliersstring1: 'אין ספקים עדיין',
    emptysuppliersstring2: 'כשיהיו - הם יופיעו כאן',
    addsupplier: 'הוספת ספק חדש',
    category: 'קטגוריה',
    suppliername: 'שם הספק',
    supplierphone: 'מספר הטלפון של הספק',
    searchbystudent: 'חיפוש על פי שם התלמיד',
    cantsetinsideteststudent: 'אין אפשרות לקבוע לתלמיד זה מבחן פנימי',
    ptorfrompayment: 'פטור מתשלום',
    ptor_reason: 'סיבת הפטור',
    automaticStudentApproval: 'אישור תלמידים אוטומטי',
    myweeklyAgendamystudents: 'המבחן פנימי של התלמידים שלי',
    manageItems: 'ניהול ומכירת פריטים',
    sellitem: 'מכירת פריט',
    itemname: 'שם הפריט / תיאור',
    makat: 'מק"ט',
    price: 'מחיר כולל מע"מ',
    searchstudentbynm: 'חפש תלמיד/ה לפי שם פרטי / שם משפחה / מספר טלפון / מספר ת.ז',

    /* POST SEND 23/09/20 */
    needtochooseteacherlicenses: 'עלייך לבחור מורה / מנהל מקצועי לפני שתוכל/י לבחור את סוג הרישיון',
    addStudentMessagePaymentPostSignup: 'הודעה שלאחר תשלום דמי רישום',
    addStudentMessagePaymentPostInsideTest: 'הודעה שלאחר תשלום דמי מבחן פנימי',
    supplierfee: 'אחוז מע"מ מוכר',
    suppliercanelsesee: 'הרשאת צפייה מחוץ למנהל',
    minimalageerror: 'התלמיד לא בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    minimalageerrorpersonal: 'אנו מצטערים, אינך בגיל המינימלי להוצאת רשיון מכל סוג שהוא',
    paidbeforedate: 'תאריך תשלום בעבר',
    padibeforeinvoice: 'מספר החשבונית',
    statusBrosh: 'סטטוס ברוש',
    didSignedWithBrosh: 'האם נרשם בברוש בעבר?',
    moneyTransferSettings: 'הרשאות משיכת כספים',
    canWithdrawMoney: 'האם יכול למשוך כספים? לחצו לשינוי',
    clientName: 'שם הלקוח',
    clientPhoneNumber: 'מספר הטלפון של הלקוח',
    messageSystem: 'מערכת ההודעות',
    emptymessagesstring1: 'אין הודעות ששידרתם',
    emptymessagesstring2: 'כשיהיו - הן יופיעו כאן',
    message: 'הודעה',
    broadcastat: 'שודר בתאריך',
    pause: 'השהיית הודעה',
    mymessages: 'ההודעות שלי',
    messagereceivedat: 'ההודעה התקבלה ב',
    canSeeInsideTests: 'מותר למשתמש לצפות בלוח המבחנים הפנימיים',
    canSellItems: 'יכול למכור פריטים',
    canTransferStudent: 'יכול להעביר תלמידים',
    statusBroshInsideTest: 'האם עודכן בברוש שעבר/ה מבחן פנימי',
    mymessagessivan: 'לוח מכירות/קניות קמע מחשבים',
    newmessageboardsivan: 'הוספת מודעה חדשה',
    refunds: 'זיכויים',
    refundables: 'הרשומות הניתנות לזיכוי',
    emptyrefundablesstring: 'אין רשומות הניתנות לזיכוי - כשיהיו, הן יופיעו כאן',
    canSetInsideTestsProfessional: 'האם מנהל מקצועי יכול לקבוע לעצמו מבחן פנימי',
    canGivePtorSignup: 'האם יכול לתת פטור מדמי רישום',
    canGivePtorInsideTest: 'האם יכול לתת פטור מבחן פנימי',
    mycashregisterandtransfermoneyasgizbar: 'הקופה שלי ומשיכת כספים - כגזבר',
    mycashregisterandtransfermoneyasteacher: 'הקופה שלי ומשיכת כספים - כמורה/מנהל מקצועי',
    canSetInsideTestsToOthers: 'יכול לקבוע מבחן פנימי למשתמשים אחרים',
    daily: 'יומי',
    weekly: 'שבועי',
    monthly: 'חודשי',
    other: 'אחר',
    rishumBabroshDates: 'רישום מבחן פנימי בברוש',
    canRemoveInsideTest: 'יכול להחזיר מבחן פנימי למאגר',
    canSetInsideTestsToOthersStudents: 'יכול לקבוע מבחן פנימי לתלמידים לא שלו (מנהל מקצועי/מורה/מנהל מקצועי)',
    canCollectCash: 'יכול לקבל מזומן',
    canCollectCheque: 'יכול לקבל שיק',
    canCollectBankTransfer: 'יכול לקבל העברה בנקאית',
    canCollectApplication: 'יכול לקבל העברה באפליקציית תשלומים',
    canCollectTranzila: 'יכול לקבל העברה בסליקה',
    canCollectBarter: 'יכול לבצע העברה בברטר',
    canDeposit: 'יכול לבצע הפקדות',
    bank: 'בנק',
    branch: 'סניף',
    branchNumber: 'סניף',
    bankAccountNumber: 'מספר חשבון',
    amount: 'סכום',
    depositaccounts: 'חשבונות הפקדה',
    deposit: 'הפקדה',
    choosedepositaccount: 'בחר חשבון להפקדה',
    account: 'חשבון',
    discoverPayments: 'מבט על - תשלומים',
    canDiscoverPayments: 'יכול לצפות במבט-על על תשלומים',
    canSeeTeachersCashRegister: 'צפה בקופות מורים',
    canEditInsideTests: 'יכול לערוך מבחנים פנימיים',
    canRefund: 'יכול לזכות תלמידים',
    canGivePtors: 'בעל הרשאה לתת פטורים',
    whoGaveThePtor: 'מי נתן הוראת הפטור',
    canSeePtorsGiven: 'יכול לצפות במעקב פטורים',
    /* 31/10/20 */
    canDoInsideTestsToMyStudents: 'יכול לעשות מבחן פנימי לתלמידים שלו',
    depositBy: 'הפקדה ע"י',
    teacherName: 'שם המורה',
    boardwithoutlogin: 'לכניסה ללוח המודעות ללא הרשמה, לחצו כאן',
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    contactus: 'צור קשר',
    fullname: 'שם מלא',
    contactusabout: 'הפנייה',
    productexp: 'תפוגת המוצר',
    subcategory: 'תת קטגוריה',
    buycost: 'מחיר עלות',
    wantedprice: 'מחיר מבוקש',
    freetext: 'טקסט חופשי',
    chooseproductimages: 'לחצו לבחירת תמונת המוצר',
    filechosen: 'הקובץ שנבחר',
    uploadtoboard: 'להעלאת המודעה לחצו כאן',
    pricesimple: 'מחיר',
    isproductexp: 'האם המוצר בעל תוקף?',
    paymentsdocument: 'הפקת מסמך כספי תקופתי'
  },
  en: {
    myschool: 'My School',
    myschools: 'My Schools',
    school: 'school',
    main: 'main',
    studentsmanagement: 'Students Management',
    myemployees: 'My teachers, professional manager and clerksי',
    employeename: 'employee name',
    position: 'position',
    createdat: 'created at',
    edit: 'edit',
    status: 'status',
    frozen: 'paused',
    active: 'active',
    emptyemployeesstring1: "You don't have employees, please click on the plus button",
    emptyemployeesstring2: 'in order to add an employee',
    addnewemployee: 'Add a new employee',
    firstname: 'first name',
    lastname: 'last name',
    phonenumber: 'phone number',
    idnumber: 'ID number',
    email: 'Email address',
    type: 'Type',
    teacher: 'Teacher',
    professional: 'Professional manager',
    professionalteacher: 'teacher/professional manager',
    clerk: 'Clerk',
    professionalnumber: 'Professional management numberת',
    ispartner: 'Is a partner',
    yes: 'Yes',
    no: 'No',
    signuppaymentnumberofigrot: 'Registeration fee, number of monthly school fees',
    insidetestpaymentnumberofigrot: 'Internal test fee, number of monthly school fees',
    signuppaymentcoststudent: 'Internal test cost per student',
    insidetestpaymentcoststudent: 'Internal test - receipt of the teacher',
    signuppaymentcostteacher: 'Registeration fee cost per student',
    insidetestpaymentcostteacher: 'Registeration fee - receipt of the teacehr',
    password: 'Password',
    create: 'Create',
    update: 'Update',
    editexistingemployee: 'Edit an existing user',
    freezeemployee: 'Do you want to pause the employee?',
    addnewstudent: 'Add a new student',
    birthday: 'Birthday',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    othergender: 'Prefer not to specify',
    licensetype: 'Driving license type',
    allowedlicensetypelearn: 'Types of licenses allowed for study',
    studentstatus: 'Student status',
    active: 'Active',
    inactive: 'Inactive',
    passedtest: 'Passed test',
    cansignwithbrosh: 'Permitted to sign in to the "Brosh"',
    broshsignupdate: '"Brosh" sign in date',
    broshclerk: 'Registering clerk',
    paidsignupfee: 'Paid registeration fee',
    ptor: 'Exempted',
    paidinsidetestfee: 'Paid internal test fee',
    editschool: 'Edit school',
    schoolname: 'School name',
    schooladdress: 'School address',
    postalcode: 'School postal code',
    schoolemail: 'School email address',
    cpaemail: 'CPA email address',
    cpe: 'CPA',
    officialidnumber: 'Official business number',
    schooladdress: 'Mailing address',
    receipttaxinvoice: 'Receipt tax invoice',
    invoicenumber: 'Invoice',
    receipt: 'Receipt',
    editstudent: 'Edit student',
    deletestudent: 'Delete student',
    studentstatus: 'Student status',
    officename: 'CPA office name',
    fax: 'Fax number',
    cpeaddress: 'CPA office address',
    cpepostalcode: 'CPA office postal code',

    studentadd: 'Add student',
    getexternalstudentlink: 'Get a link to add an external studentת',
    mystudents: 'My students',
    emptystudentstring1: 'You have no students, please click',
    emptystudentstring2: 'to add a new student',
    studentname: 'Student name',
    getexternalstudentlinktitle: 'Students for approval - from an external linkי',
    nostudentstoapprove1: 'There are no students for approval',
    nostudentstoapprove2: 'Add a new student',
    delete: 'Delete',
    approve: 'Approve',
    selfsignupthankyou: 'Dear student, Thank you very much for your cooperation. We will contact you soon!',
    selfsigndearstudent: 'Dear student, please fill in the details and click on the confirmation button',
    selfsignattheend: 'Upon completion, a notice will be sent to the school. Thank you for your cooperation,',

    login: 'Login',
    username: 'Username',

    teachername: 'Teacher name',

    licensetypeA1: 'A1 - motorcycle up to 46.47 horsepower (35 kilowatts)',
    licensetypeA2: 'A2 - Motorcycle up to 6.14 horsepower and up to 11 kilowatts',
    licensetypeA: 'A - Motorcycle whose engine power exceeds 46.47 horsepower (35 kW)',
    licensetypeB: 'Manual B - Motor vehicle with a total weight allowed up to 3,500 kg, may carry up to 8 passengers except the driver, even when a tow truck weighing up to 1,500 kg',
    licensetypeB_: 'B Automatic - motor vehicle with a total weight allowed up to 3,500 kg, may carry up to 8 passengers except the driver, even when a trailer weighs up to 1,500 kg',
    licensetypeC: 'C - Commercial motor vehicle and work vehicle with a total weight allowed over 12,000 kg, even when a towed trailer with a total weight allowed up to 3,500 kg',
    licensetypeC1: 'C1 - Commercial motor vehicle and work vehicle with a total weight allowed over 3,500 kg and up to 12,000 kg, even when a towed trailer with a total weight is allowed up to 3,500 kg',
    licensetypeCA: 'C + E - Commercial motor vehicle and work vehicle with a total weight allowed over 3,500, even if adjacent to it towed or supported',
    licensetypeD: 'D - Bus',
    licensetypeD1: 'D1 - Taxi, tour vehicle and public minibus in total weight allowed up to 5,000 kg and number of seats up to 16 passengers in addition to the driver',

    migrate: 'Transfer student',
    migrateAction: 'Transfer student',
    chooseteacher: 'Choose a teacher / professional manager',

    errorPleaseTryAgain: 'Error connecting to server, please try again. If the problem recurs - please contact our support. Sorry for the temporary inconvenience.',
    messageCopiedSuccess: 'The link was successfully copied',
    messageStudentUpdateSuccess: 'The student was successfully updated',
    messageSchoolCreatedSuccess: 'A new school was successfully created',
    messageLoggedOutSucces: 'You have successfully logged out',
    mustFillAllFields: 'You must fill in all required fields',
    messageManagerUpdatedSuccess: 'Manager successfully updated',
    phoneNotGood: 'Please fill in a correct phone number',
    idNumberNotGood: 'ID number is incoreect',
    messageUserCreatedSuccess: 'User created successfully',
    messageStudentAlreadyExists: 'Student already exists and belongs to teacher',
    messageDearStudentAlreadyExists: 'Dear student, it seems that your account already exists with the teacher:',
    messsageThankYouContactShortly: 'Thank you very much! We will contact you soon ',
    messageUserUpdatedSuccess: 'User updated successfully',
    messageDeletionCancelled: 'Deletion Canceled',
    messageSchoolUpdatedSuccess: 'School successfully updated',
    messageStudentDeletedSuccess: 'Student deleted successfully',
    canAddClerk: 'Can add a clerk',
    canBeChargedTwiceForSignupFee: 'Can this student be charged more than once for registration fees',
    chooseteacherAndShareLink: 'Choose a teacher and share his student registration link',

    addnewstudentpayment: 'Add a new payment record',
    choosestudent: 'Select a student from the list',
    opencalendarhere: 'Click on me to open the calendar',
    choosedateofpayment: 'Choose the payment date',
    paywith: 'Pay through',

    cash: "Cash",
    cheque: "Cheque",
    invoiceDelivered: 'Invoice generated',
    bankTransfer: 'Bank transfer',
    creditCard: 'credit card',
    application: 'Application',
    tranzila: 'pay online',
    barter: 'barter',
    ptor: 'exemption',
    amountWithVAT: 'Amount to be paid (including VAT)',
    payfor: 'pay for',
    signupFee: 'Registration Fee',
    signup: 'Registration',
    insideTestFee: 'Internal Test Fee',
    insideTest: 'Internal Test',
    choosebank: 'Select bank',
    branchNumber: 'Branch number',
    bankAccountNumber: 'Bank Account Number',

    bankLeumi: 'Bank Leumi - 10',
    bankIsrael: 'Bank of Israel - 99',
    bankHapoalim: 'Bank Hapoalim - 12',
    discontBank: 'Discount Bank - 11',
    tfahotBank: 'Mizrahi Tefahot Bank - 20',
    internationalBank: 'First International Bank - 31',
    otzarHayalBank: 'Otzar Hachayal Bank - 14',
    markantilDiscontBank: 'Mercantile Discount Bank - 17',
    doarBank: 'Post Bank - 09',
    igudBank: 'Union Bank - 13',
    masadBank: 'Bank Masad - 46',
    poaliAgudatBank: 'Bank Poalei Agudat Israel - 52',
    mashkantaotDiscontBank: 'Mortgage Discount Bank - 90',
    mashkantaotLeumiBank: 'Leumi Mortgage Bank Ltd. - 77',
    yahavBank: 'Yahav Bank for Civil Servants - 04',
    yovankBank: 'Yubank Bank Ltd. - 26',
    pituhaTayasiaBank: 'Industrial Development Bank - 07',
    sapanutBank: 'Israel Shipping Bank - 08',
    yerushalayimBank: 'Bank of Jerusalem - 54',
    arabiIsraelBank: 'Bank of Israeli Arabs - 34',
    unitedEuroBank: 'Euro Trade Bank - 01',
    haklautBank: 'Israel Land Bank - 19',
    citiBank: 'Bank City - 22',
    kupatHaovedAlumiBank: 'National Employee Fund - 48',
    otzarHashiltonBank: 'Local Government Treasury Bank - 68',
    daskiaBank: 'Dexia Bank - 68',
    hasbcBank: 'HSBC Bank - 23',
    arabLandBank: 'Arab Land Bank - 67',
    bankOfIndiaBank: 'Bank of India - 39',

    bit: 'Bit',
    paybox: 'Paybox',
    pepper: 'Pepper',
    chooseapplication: 'Select application',
    addpaymentlink: 'Add a new payment record',
    mypayments: 'My Payment Records',
    paidfor: 'Paid for',
    nopayments1: 'Payment records do not exist',
    nopayments2: '',
    dateOfPayment: 'Payment Date',
    item: 'Item',
    paymentType: 'Paid via',

    messagePaymentCreatedSuccess: 'Payment record successfully created',
    isGizbar: 'Is treasurer?',
    addStudentMessage: 'Personal Message - Student Registration',
    messageSpecialMessageUpdatedSuccess: 'Personal message successfully updated',
    specialMessage: 'Personal Message',
    editSpecialMessage: 'Edit personal message',
    editSpecialMessage2: 'This message will appear when you send a student registration link',
    addStudentMessagePayment: 'Fixed note for financial document',
    paymentMessage: 'The regular note for a financial document',
    editPaymentMessage: 'Edit a permanent message for a financial document',
    editPaymentMessage2: 'This message will appear when sending a link to a new payment record',
    orsendlinkpayment: 'or alternatively, send a payment link',
    sendpaymentlink: 'To send a payment link to a student, click here',
    sendoutsidepaymentlinkTItle: 'Send student pay link',

    sendWhatsapp: 'Send an invoice in WhatsApp',
    invoicereceiptandpaymentcreatedsuccess: 'Payment record and receipt tax invoice created successfully',
    backtohomepage: 'Back to home page, click here',
    todownloadreceipt: 'To download the receipt, click here',
    todownloadinvoice: 'To download the invoice click here',
    orH: 'or alternatively',
    sendwhatsappreceipt: 'Send the WhatsApp invoice to the student at the click of a button',
    sendwhatsappreceiptteacher: 'Send the WhatsApp invoice to the teacher at the click of a button',
    sendwhatsappreceiptInsideTest: "Send the student's invoice on WhatsApp",
    sendwhatsappreceiptteacherInsideTest: 'Send the invoice to the teacher on WhatsApp',
    setInsideTest: 'Set an internal test',
    chooseproffesional: 'Choose a professional manager who will pass the test',
    choosedateoftest: 'Internal test date selection',
    opencalendarheretime: 'Click on me to open the clock',
    choosedateoftesttime: 'Test time selection',
    messageTestCreatedSuccess: 'Internal test successfully set',
    myweeklyAgenda: 'My Internal Test Board',
    createDocument: 'Generate document',
    not_ok: 'Incorrect',
    half_ok: 'Partially correct',
    inside_test_ok: 'correct',
    inside_test_bad: 'Expropriation of control',
    passedHappy: 'Passed',
    notPassedSad: 'Not passed',
    unkown: 'Unknown',
    insidetest_title: 'Internal Test',
    emailaddress: 'Email Address',
    changepwd: 'Change password',
    passwordChangedSuccess: 'Password successfully updated',
    insidetestupdatedSuccessMessage: 'Internally tested successfully updated',
    cantpayanything: 'Please note: this student is unable to register because he has exceeded the registration quota on the registration fee and the MP fee',
    dearstudentcantpay: 'Dear student, it seems you have exceeded the payment quota for this item, please contact your teacher',
    all: 'all',
    cashregister: 'cash at checkout',
    pullmoney: 'Withdraw money',
    mycashregister: 'The money in my cash register',
    transfermoney: 'Transfer money',
    moneytransfersuccessmessage: 'Money transferred successfully',
    mycashregisterandtransfermoney: 'My Cash and Withdrawal',
    canAddEmployees: 'Can add users',
    manageEmployees: 'Employee Management',
    employeesinmyorg: 'Employees in my organization',
    employeesinmyorgstring1: 'There are no employees to manage in your organization',
    employeesinmyorgstring2: 'When they are - they will appear here',
    mycommissioncashregister: 'My commissions cash register:',
    generateinvoices: 'Invoice Generation',
    studentname: 'Student name',
    callteacher: 'Call teacher',
    callstudent: 'Call student',
    generateinvoice: 'Generate Invoice',
    oldinvoices: 'Invoices generated from payment records',
    nooldinvoices1: 'No invoices already generated',
    nooldinvoices2: 'When they are, they will appear here',
    noinvoices1: 'No production invoices',
    noinvoices2: 'When they are, they will appear here',
    invoiceready: 'Invoice ready',
    setWithoutdates: 'Click here to send without date and time',
    setDates: 'Click to set MP date',
    creditCardnumber: 'credit card number',
    cvvnumber: 'Three digits on the back of the card (CVV)',
    expdatenumber: 'Card expiration Date',
    month: 'month',
    year: 'Year',
    insidetestlist: 'Internal Test Board',
    searchbydates: 'Search by dates',
    teacherportal: 'Click to go to teacher / professional manager view',
    clerkportal: 'Click to go to official view',
    writeacheque: "Registration of a payment to a teacher / professional director",
    nocheques1: "No payment records available",
    nocheques2: "When they are - they will appear here",
    newcheque: "New Payment Record",
    choosenimanfromthelist: "Select the employee from the list",
    chequenum: "Cheque number",
    chequeamount: "Amount paid with cheque",
    notmendatorycheque: "Not required, only if the teacher has submitted the invoice for the payment",
    eacherinvoicenumber: 'Teacher Invoice Number',
    teacherinvoiceamount: 'Teacher Invoice Amount',
    chequeamountnotequal: "Payment amount per teacher, does not match submitted invoice",
    pleasefillall: "Please fill in all the details before creating",
    amountOnCheque: "The amount paid",
    invoicecreated: "Does invoice expense",
    teacherInvoiceNumber: "Invoice Number",
    teacherInvoiceAmount: "Amount on invoice",
    attachinvoice: "snap invoice",
    canSeeCommission: 'Can I see a commission?',
    isbanktransferc: 'Is there a bank transfer?',
    bankTransfer: 'the amount paid',
    paymentsschool: 'School Payment Records',
    paymentsschoolteachers: 'Teacher Payment Records',
    paymentsschoolall: "To view all the school's payment records click here",
    suppliersList: 'To the list of suppliers',
    mysupplierslist: 'My Suppliers',
    emptysuppliersstring1: 'No vendors yet',
    emptysuppliersstring2: 'When they are - they will appear here',
    addsupplier: 'Add new provider',
    category: 'Category',
    suppliername: 'Supplier name',
    supplierphone: "Supplier's phone number",
    searchbystudent: 'Search by student name',
    cantsetinsideteststudent: 'It is not possible to set this student',
    ptorFromPayment: 'Exemption from payment',
    ptor_reason: 'Reason for exemption',
    automaticStudentApproval: 'Automatic Student Approval',
    myweeklyAgendamystudents: "My Students' tests agneda",
    manageItems: 'Manage and sell items',
    sellitem: 'Selling an item',
    itemname: 'Item Name / Description',
    makat: 'catalog number',
    price: 'price with V.A.T',
    searchstudentbynm: 'Search for a student by first name / last name / phone number / ID number',
    needtochooseteacherlicenses: "You must select a professional teacher / administrator before you can select the license type",
    addStudentMessagePaymentPostSignup: 'Registering fee fixed message',
    addStudentMessagePaymentPostInsideTest: 'Internal test fee fixed message',
    supplierfee: 'Supplier VAT percentage',
    suppliercanelsesee: 'Off-screen viewing permission',
    minimalageerror: 'The student is not of the minimum age to issue a license of any kind',
    minimalageerrorpersonal: 'Sorry, you are not of the minimum age to issue a license of any kind',
    paidbeforedate: 'past payment date',
    padibeforeinvoice: 'Invoice Number',
    statusBrosh: 'StatusBrosh',
    didSignedWithBrosh: 'Have you registered with Cypress in the past?',
    moneyTransferSettings: 'Withdrawal Permissions',
    canWithdrawMoney: 'Can withdraw funds? Click to change ',
    clientName: 'Client Name',
    clientPhoneNumber: "Customer's Phone Number",
    messageSystem: 'Message system',
    emptymessagesstring1: 'No messages you sent',
    emptymessagesstring2: 'When they are - they will appear here',
    message: 'Message',
    broadcastat: 'aired on',
    pause: 'Post delay',
    mymessages: 'My messages',
    messagereceivedat: 'Message received on',
    canSeeInsideTests: 'User is allowed to view the internal test panel',
    canSellItems: 'can sell items',
    canTransferStudent: 'can transfer students',
    statusBroshInsideTest: 'Has been updated with the last Brosh internal tests',
    mymessagessivan: 'Sales Board / Computer Mascot Shopping',
    newmessageboardsivan: 'Add New Message',
    refunds: 'refunds',
    refundables: 'Refundable records',
    emptyrefundablesstring: 'There are no credible records - if any, they will appear here',
    canSetInsideTestsProfessional: 'Can a professional administrator set up an internal tests',
    canGivePtorSignup: 'Can grant exemption from registration fees',
    canGivePtorInsideTest: 'Can give exemption to internal tests',
    mycashregisterandtransfermoneyasgizbar: 'My Cash and Withdrawal - As Treasurer',
    mycashregisterandtransfermoneyasteacher: 'My Fund and Withdrawal - as a Teacher / Professional Manager',
    canSetInsideTestsToOthers: 'can set internal tests for other users',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'monthly',
    other: 'other',
    rishumBabroshDates: 'Brosh internal tests registration',
    canRemoveInsideTest: 'can return MP to database',
    canSetInsideTestsToOthersStudents: 'Can set a non-his / her student internal tests (professional director / teacher / professional director)',
    canCollectCash: 'can get cash',
    canCollectCheque: 'can get chic',
    canCollectBankTransfer: 'Can accept bank transfer',
    canCollectApplication: 'Can receive a payment application transfer',
    canCollectTranzila: 'can receive clearance transfer',
    canCollectBarter: 'Can make a transfer at Barter',
    canDeposit: 'can make deposits',
    bank: 'Bank',
    branch: 'branch',
    branchNumber: 'branch',
    bankAccountNumber: 'Account Number',
    amount: 'Amount',
    depositaccounts: 'Deposit Accounts',
    deposit: 'deposit',
    choosedepositaccount: 'Select a deposit account',
    account: 'account',
    discoverPayments: 'Payments - Payments',
    canDiscoverPayments: 'Can view payments at a glance',
    canSeeTeachersCashRegister: 'View Teacher Boxes',
    canEditInsideTests: 'can conduct internal tests',
    canRefund: 'can win students',
    canGivePtors: 'Authorized to grant exemptions',
    whoGaveThePtor: 'Who gave the exemption order',
    canSeePtorsGiven: 'can watch exemption tracking',
    canDoInsideTestsToMyStudents: 'can do internal tests for his students',
     depositBy: 'Deposit by',
     teacherName: 'Teacher Name',
     boardwithoutlogin: 'Go to the buy and sell board',
     contactus: 'Contact us',
     fullname: 'Full name',
     contactusabout: 'Message',
     contactus: 'Contact us',
     productexp: 'Prodcut expiration date',
     subcategory: 'sub-category',
     buycost: 'Cost price',
     wantedprice: 'Asking price',
     freetext: 'Free text',
     chooseproductimages: 'Click to upload the product image',
     filechosen: 'file chosen',
     uploadtoboard: 'Submit',
     pricesimple: 'Price',
     isproductexp: 'Does the product have expiration date?',
     paymentsdocument: 'הפקת מסמך כספי תקופתי'
  },
}
