import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Login from './pages/Login';
import Loading from './pages/Loading';
import SivanBoard from './pages/SivanBoard';
import NewSivanBoardMessage from './pages/NewSivanBoardMessage';
import EditSivanBoardMessage from './pages/EditSivanBoardMessage';
import SivanBoardPage from './pages/SivanBoardPage';
import SivanBoardGuest from './pages/SivanBoardGuest';
import SivanBoardPageGuest from './pages/SivanBoardPageGuest';
import NewSivanBoardMessageGuest from './pages/NewSivanBoardMessageGuest';
import ContactUsBoard from './pages/ContactUsBoard';

import Cookies from 'universal-cookie';
import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class App extends Component {
  componentDidMount() {
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isConnected: false,
     type: '',
     loading: false,
     isManager: false
   }
  }

  validate = () => {
    this.setState({loading: true});
    axios.get(API + 'validate', {
      headers: { Authorization: `Bearer ${cookies.get('token___school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({isConnected: true, type: response.data.data.type, isManager: response.data.data.isManager, loading: false});
      }
    }).catch(err => {
      this.setState({isConnected: false, loading: false});
    });
  }

  guest = () => {
    return (
      <Switch>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/board/page'>
          <SivanBoardPageGuest />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessageGuest />
        </Route>
        <Route path='/board'>
          <SivanBoardGuest />
        </Route>
        <Route path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route path="/">
          <SivanBoardGuest />
        </Route>
      </Switch>
    );
  }

  connected = () => {
    return (
      <Switch>
        <Route exact path='/board/page'>
          <SivanBoardPage />
        </Route>
        <Route path='/board/edit'>
          <EditSivanBoardMessage />
        </Route>
        <Route path='/board/add'>
          <NewSivanBoardMessage />
        </Route>
        <Route path='/board'>
          <SivanBoard />
        </Route>
        <Route path='/contact-us'>
          <ContactUsBoard />
        </Route>
        <Route path="/">
          <SivanBoard />
        </Route>
      </Switch>
    );
  }


  render() {
    return (
      <Router>
        {this.state.loading ? (
          <div>
            <Switch>
              <Route path="/">
                <Loading />
              </Route>
            </Switch>
          </div>
        ) : (
          <div>
            {this.state.isConnected ? (
              <div>
                {this.connected()}
              </div>
            ) : (
              <div>{this.guest()}</div>
            )}
          </div>
        )}
      </Router>
    )
  }


}

export default App;
